import { useSelector, useDispatch } from "react-redux";

import { setOption, setSidebarAttribute, setSnippetAttribute } from "reduxStore/snippet-page/snippet-page.actions";
import settings from "utils/settings";

const usePrimaryEditorHeader = () => {
    const { snippet, options, sidebar, primaryEditorRef, scratchpadEditorRef } = useSelector((state) => state.snippetPage);
    const dispatch = useDispatch();

    const onOptionChange = ({ name, value }) => {
        dispatch(setOption({ name, value }));
    };

    const onScratchpadToggle = () => {
        let newSize = options.panelSize ? 0 : 450;
        onOptionChange({ name: "panelSize", value: newSize });
        setTimeout(forceResize, 0);
    };

    const onSidebarToggle = () => {
        let newSize = sidebar.size ? 0 : settings.sidebarSize.default;
        dispatch(setSidebarAttribute({ name: "size", value: newSize }));
    };

    const onValueReset = (value) => {
        primaryEditorRef?.current && primaryEditorRef.current.setValue(value);
        dispatch(setSnippetAttribute({ name: "value", value }));
    };

    const forceResize = () => {
        primaryEditorRef?.current && primaryEditorRef.current.forceResize();
        scratchpadEditorRef?.current && scratchpadEditorRef.current.forceResize();
    };

    return {
        snippet,
        options,
        sidebar,
        onOptionChange,
        onScratchpadToggle,
        onSidebarToggle,
        onValueReset,
    };
};

export default usePrimaryEditorHeader;

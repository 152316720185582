import React from "react";

import Mode from "components/Editor/EditorOptions/Mode";
import Gutter from "components/Editor/EditorOptions/Gutter";
import Wrap from "components/Editor/EditorOptions/Wrap";
import FontSize from "components/Editor/EditorOptions/FontSize";
import EditorFunctions from "components/Editor/EditorFunctions";

const ScratchpadEditorHeader = ({ value, onValueChange, options, onChange, show }) => {
    const onOptionsChange = (e) => {
        let newOptions = { ...options, [e.name]: e.value };
        onChange(newOptions);
    };

    return (
        <div className="editor-header d-flex align-items-center justify-content-between" style={{ display: show ? "block" : "none" }}>
            <div className="d-flex align-items-center justify-content-start">
                <EditorFunctions value={value} onChange={onValueChange} mode={options.mode} idPrefix="scratchpad" />
            </div>
            <div className="d-flex align-items-center justify-content-end">
                <FontSize value={options.fontSize} onChange={onOptionsChange} />
                <Gutter value={options.showGutter} onChange={onOptionsChange} idPrefix="scratchpad" />
                <Wrap value={options.wrapEnabled} onChange={onOptionsChange} style={{ marginLeft: "2px" }} idPrefix="scratchpad" />
                <Mode value={options.mode} onChange={onOptionsChange} style={{ marginLeft: "2px" }} />
            </div>
        </div>
    );
};

export default ScratchpadEditorHeader;

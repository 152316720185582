import React, { useState } from "react";
import Select from "react-select";

import useSelectStyles from "utils/hooks/useSelectStyles";

const Mode = ({ value, onChange, style, ...rest }) => {
    const [modeOptions] = useState([
        { value: "javascript", label: "javascript" },
        { value: "json", label: "json" },
        { value: "markdown", label: "markdown" },
        { value: "scss", label: "scss" },
        { value: "sql", label: "sql" },
        { value: "text", label: "text" },
    ]);

    const onModeChange = (option) => {
        onChange({ name: "mode", value: option.value });
    };

    const { styles, IndicatorSeparator, DropdownIndicator } = useSelectStyles({
        menuStyle: { zIndex: 10 },
        controlStyle: { minHeight: "35px" },
    });

    return (
        <div style={{ ...style, width: "120px", minWidth: "120px" }} {...rest}>
            <Select
                value={value ? { value, label: value } : null}
                options={modeOptions}
                onChange={onModeChange}
                placeholder="mode..."
                styles={styles}
                components={{ IndicatorSeparator, DropdownIndicator }}
            />
        </div>
    );
};

export default Mode;

import {
    FETCH_SETTINGS_BEGIN,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAILURE,
    POST_SETTINGS_BEGIN,
    POST_SETTINGS_SUCCESS,
    POST_SETTINGS_FAILURE,
} from "./settings.actions";

const initialState = {
    data: {},

    isFetching: false,
    fetchError: null,

    isPosting: false,
    postError: null,
};

export default function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SETTINGS_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchError: null,
            };
        case FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
            };
        case FETCH_SETTINGS_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload,
                data: {},
            };

        case POST_SETTINGS_BEGIN:
            return {
                ...state,
                isPosting: true,
                postError: null,
            };
        case POST_SETTINGS_SUCCESS:
            return {
                ...state,
                isPosting: false,
                data: action.payload,
            };
        case POST_SETTINGS_FAILURE:
            return {
                ...state,
                isPosting: false,
                postError: action.payload,
            };
        default:
            return state;
    }
}

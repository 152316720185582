import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import useAuth from "utils/hooks/useAuth";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </div>
));

const UserDropdown = () => {
    const { user } = useSelector((state) => state.auth.data);
    const { logout } = useAuth();

    return (
        <Dropdown className="user-dropdown">
            <Dropdown.Toggle as={CustomToggle}>
                <div className="user-dropdown-toggle noselect">
                    <i className="fas fa-user" />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {user && (
                    <>
                        <div className="dropdown-item-text text-muted">{user.username}</div>
                        <div className="dropdown-item-text text-muted">{user.email}</div>
                    </>
                )}
                <div className="dropdown-item" onClick={logout} style={{ cursor: "pointer" }}>
                    <i className="fal fa-sign-out mr-2" />
                    log out
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserDropdown;

import { useState } from "react";
import { toast } from "react-toastify";

import useReduxDataPutting from "utils/hooks/useReduxDataPutting";
import useReduxDataDeleting from "utils/hooks/useReduxDataDeleting";
import { putLabel, deleteLabel } from "reduxStore/labels/labels.actions";

const useLabelEntry = ({ label }) => {
    const { putData } = useReduxDataPutting(putLabel, "labels");
    const { deleteData } = useReduxDataDeleting(deleteLabel, "labels");

    const [name, setName] = useState(label.name);

    const onChange = (evt) => {
        const { value } = evt.target;
        setName(value);
    };

    const updateLabel = async () => {
        const success = await putData({ id: label._id, item: { name } });
        if (success) {
            toast(`label "${name}" updated.`.toLowerCase());
        }
    };

    const onUpdateClicked = async () => {
        if (name === label.name || !name) return;
        updateLabel();
    };

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            onUpdateClicked();
        }
    };

    const removeLabel = async () => {
        const success = await deleteData(label._id);
        if (success) {
            toast(`label "${name}" deleted.`.toLowerCase());
        }
    };

    return {
        // fns
        onChange,
        handleKeyPress,
        onUpdateClicked,
        removeLabel,

        // data
        name,
    };
};

export default useLabelEntry;

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { css } from "emotion";
import { Button as ReactBootstrapButton } from "react-bootstrap";
import Color from "color";

import { getThemeColour } from "utils/util";
import { useSelector } from "react-redux";

const Button = (props) => {
    const { variant } = props;
    const theme = useSelector((state) => state.theme);

    useEffect(() => {
        const getStyle = ({ variant, theme }) => {
            let colorObj = null;
            if (variant.charAt(0) === "#") {
                colorObj = Color(variant);
                return getThemeStyles({ colorObj });
            } else {
                if (variant.split("-")[0] === "outline") {
                    if (variant.split("-")[1].charAt(0) === "#") {
                        colorObj = Color(variant.split("-")[1]);
                    } else {
                        colorObj = Color(getThemeColour(variant.split("-")[1], theme));
                    }
                    return getOutlineStyles({ colorObj });
                } else {
                    colorObj = Color(getThemeColour(variant, theme));
                    return getThemeStyles({ colorObj });
                }
            }
        };

        let newStyle = getStyle({ variant, theme });
        setStyle(newStyle);
    }, [variant, theme]);

    const [style, setStyle] = useState(null);

    const getThemeStyles = ({ colorObj }) => {
        let colorRGB = colorObj.object();
        let hex = colorObj.hex();
        let newStyle = {
            color: colorObj.isLight() ? "#232323" : "#c7c7c7",
            backgroundColor: hex,
            borderColor: hex,
            boxShadow: `0 0 0 0.2rem rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.5) !important`,

            colorHover: colorObj.darken(0.23).isLight() ? "#232323" : "#c7c7c7",
            backgroundColorHover: colorObj.darken(0.23).hex(),
            borderColorHover: colorObj.darken(0.23).hex(),

            colorFocus: colorObj.darken(0.23).isLight() ? "#232323" : "#c7c7c7",
            backgroundColorFocus: colorObj.darken(0.23).hex(),

            colorActive: colorObj.darken(0.305).isLight() ? "#232323" : "#c7c7c7",
            backgroundColorActive: colorObj.darken(0.305).hex(),
            borderColorActive: colorObj.darken(0.305).hex(),
        };
        return newStyle;
    };

    const getOutlineStyles = ({ colorObj }) => {
        let colorRGB = colorObj.object();
        let hex = colorObj.hex();
        let newStyle = {
            color: hex,
            backgroundColor: "transparent",
            borderColor: hex,
            boxShadow: `0 0 0 0.2rem rgba(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b}, 0.5) !important`,

            colorHover: colorObj.isLight() ? "#232323" : "#c7c7c7",
            backgroundColorHover: hex,
            borderColorHover: hex,

            colorFocus: null,
            backgroundColorFocus: null,

            colorActive: colorObj.isLight() ? "#232323" : "#c7c7c7",
            backgroundColorActive: hex,
            borderColorActive: hex,
        };
        return newStyle;
    };

    if (!style) return null;
    const { className, innerRef, ...rest } = props;

    return (
        <ReactBootstrapButton
            variant="custom"
            className={classNames(
                className,
                css({
                    color: style.color,
                    backgroundColor: style.backgroundColor,
                    borderColor: style.borderColor,
                    "&:hover:not(.disabled):not(:disabled)": {
                        color: style.colorHover,
                        backgroundColor: style.backgroundColorHover,
                        borderColor: style.borderColorHover,
                    },
                    "&:focus:not(.disabled):not(:disabled)": {
                        color: style.colorFocus,
                        boxShadow: style.boxShadow,
                        backgroundColor: style.backgroundColorFocus,
                        borderColor: style.borderColorHover,
                        "&:not(:hover)": {
                            color: style.color,
                        },
                    },
                    ":not(:disabled):not(.disabled):active": {
                        color: style.colorActive,
                        backgroundColor: style.backgroundColorActive,
                        borderColor: style.borderColorActive,
                    },
                    "&.disabled,&:disabled": {
                        color: style.color,
                        backgroundColor: style.backgroundColor,
                        borderColor: style.borderColor,
                        boxShadow: "none",
                    },
                })
            )}
            ref={innerRef}
            {...rest}
        >
            {props.children}
        </ReactBootstrapButton>
    );
};

export default Button;

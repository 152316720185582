import client from "./axios";

export const getSquads = () => {
    return client({
        method: "get",
        url: "/squads",
    });
};

export const addSquad = ({ item }) => {
    return client({
        method: "post",
        url: "/squads",
        data: item,
    });
};

export const updateSquad = ({ id, item }) => {
    return client({
        method: "put",
        url: `/squads/${id}`,
        data: item,
    });
};

export const removeSquad = async ({ id }) => {
    return client({
        method: "delete",
        url: `/squads/${id}`,
    });
};

export const leaveSquad = async ({ id }) => {
    return client({
        method: "put",
        url: `/squads/${id}/leave`,
    });
};

export const TOGGLE_CONTEXT_MENU_ON = "TOGGLE_CONTEXT_MENU_ON";
export const TOGGLE_CONTEXT_MENU_OFF = "TOGGLE_CONTEXT_MENU_OFF";

export const toggleContextMenuOn = (payload) => ({
    type: TOGGLE_CONTEXT_MENU_ON,
    payload,
});

export const toggleContextMenuOff = (key) => ({
    type: TOGGLE_CONTEXT_MENU_OFF,
    payload: key,
});

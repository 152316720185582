const settings = {
    theme: {
        // primary: "#bdea74",
        // primary: "#33cccc",
        primary: "#ff9933",
    },
    resetDeleteTimeout: 4000,
    tooltipTimeout: 750,
    debounce: {
        typing: 250,
    },
    sidebarSize: {
        default: 300,
        min: 200,
        max: 500,
    },
};

export default settings;

import { useDispatch, useSelector } from "react-redux";
import { logout } from "reduxStore/auth/auth.actions";

const TOKEN_KEY = "token";
const SQUAD_KEY = "squad";

export const authenticateUser = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const isUserAuthenticated = () => {
    return localStorage.getItem(TOKEN_KEY) !== null;
};

export const deauthenticateUser = () => {
    localStorage.removeItem(TOKEN_KEY);
};

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY);
};

export const setSquadLocal = (squadId) => {
    localStorage.setItem(SQUAD_KEY, squadId);
};

export const getSquadLocal = () => {
    return localStorage.getItem(SQUAD_KEY);
};

const useAuth = () => {
    const dispatch = useDispatch();
    const authData = useSelector((state) => state.auth);

    const performLogout = () => {
        dispatch(logout());
    };

    return {
        // fns
        logout: performLogout,
        getToken,

        // data
        isLoggingIn: authData.isLoggingIn,
        loginError: authData.loginError,
        isVerifying: authData.isVerifying,
        verifyError: authData.verifyError,
        auth: authData.data?.auth,
        user: authData.data?.user,
    };
};

export default useAuth;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { setSnippetAttribute, setOption, setSaveModal, onSave } from "reduxStore/snippet-page/snippet-page.actions";

const useSaveSnippet = () => {
    const { snippet, options, showSaveModal } = useSelector((state) => state.snippetPage);
    const dispatch = useDispatch();

    const { postError, putError, deleteError } = useSelector((state) => state.snippets);

    useEffect(() => {
        if (postError) {
            toast.error("an unknown error has occurred when adding a snippet.");
        }
        if (putError) {
            toast.error("an unknown error has occurred when updating a snippet.");
        }
        if (deleteError) {
            toast.error("an unknown error has occurred when deleting a snippet.");
        }
    }, [postError, putError, deleteError]);

    const [modeOptions] = useState([
        { value: "javascript", label: "javascript" },
        { value: "json", label: "json" },
        { value: "markdown", label: "markdown" },
        { value: "scss", label: "scss" },
        { value: "sql", label: "sql" },
        { value: "text", label: "text" },
    ]);

    const { data: categories } = useSelector((state) => state.categories);
    const { data: subcategories } = useSelector((state) => state.subcategories);
    const { data: labels } = useSelector((state) => state.labels);

    const [categoryOptions, setCategoryOptions] = useState([]);
    useEffect(() => {
        let categoryOptions = categories.map((category) => {
            return { value: category._id, label: category.name };
        });
        setCategoryOptions(categoryOptions);
    }, [categories]);

    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    useEffect(() => {
        let subcategoryOptions = subcategories.map((subcategory) => {
            return { value: subcategory._id, label: subcategory.name };
        });
        setSubcategoryOptions(subcategoryOptions);
    }, [subcategories]);

    const [labelOptions, setLabelOptions] = useState([]);
    useEffect(() => {
        let labelOptions = labels.map((label) => {
            return { value: label._id, label: label.name };
        });
        setLabelOptions(labelOptions);
    }, [labels]);

    const onSaveClicked = () => {
        dispatch(setSaveModal(true));
    };

    const onClose = () => {
        dispatch(setSaveModal(false));
        hideErrors();
    };

    const onChange = (evt) => {
        const { value, name } = evt.target;
        dispatch(setSnippetAttribute({ value, name }));
    };

    const onSelectChange = (name, option) => {
        onChange({ target: { name, value: option?.value || "" } });
    };

    const onMultiselectChange = (name, values) => {
        onChange({ target: { name, value: values?.map((v) => v.value) || [] } });
    };

    const onModeChange = (option) => {
        dispatch(setOption({ name: "mode", value: option?.value || "" }));
    };

    const [errors, setErrors] = useState([]);

    const hideErrors = () => {
        setErrors([]);
    };

    const handleSave = async () => {
        const res = await dispatch(onSave({ snippet, options, categories, subcategories, showSaveModal }));
        if (res.success) {
            dispatch(setSaveModal(false));
            toast(`snippet "${res.snippet.name.toLowerCase()}" saved.`, { type: res.type === "add" ? toast.TYPE.SUCCESS : toast.TYPE.DEFAULT });
        } else {
            setErrors(res?.errors || []);
            return;
        }
    };

    return {
        // data
        snippet,
        options,
        showSaveModal,
        modeOptions,
        categoryOptions,
        subcategoryOptions,
        labelOptions,
        errors,
        // fns
        onSaveClicked,
        onClose,
        onChange,
        onSelectChange,
        onMultiselectChange,
        onModeChange,
        handleSave,
        hideErrors,
    };
};

export default useSaveSnippet;

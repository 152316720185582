import { useState } from "react";
import { toast } from "react-toastify";

import useReduxDataPutting from "utils/hooks/useReduxDataPutting";
import useReduxDataDeleting from "utils/hooks/useReduxDataDeleting";
import { putCategory, deleteCategory } from "reduxStore/categories/categories.actions";

const useCategoryEntry = ({ category }) => {
    const { putData } = useReduxDataPutting(putCategory, "categories");
    const { deleteData } = useReduxDataDeleting(deleteCategory, "categories");

    const [name, setName] = useState(category.name);

    const onChange = (evt) => {
        const { value } = evt.target;
        setName(value);
    };

    const updateCategory = async () => {
        const success = await putData({ id: category._id, item: { name } });
        if (success) {
            toast(`category "${name}" updated.`.toLowerCase());
        }
    };

    const onUpdateClicked = async () => {
        if (name === category.name || !name) return;
        updateCategory();
    };

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            onUpdateClicked();
        }
    };

    const removeCategory = async () => {
        const success = await deleteData(category._id);
        if (success) {
            toast(`category "${name}" deleted.`.toLowerCase());
        }
    };

    return {
        // fns
        onChange,
        handleKeyPress,
        onUpdateClicked,
        removeCategory,

        // data
        name,
    };
};

export default useCategoryEntry;

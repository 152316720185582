import React, { useState } from "react";
import classNames from "classnames";

import Subcategory from "../Subcategory";
import Snippet from "../Snippet";
import LoadMore from "../LoadMore";

const Category = ({ category, loadSnippet, activeSnippet }) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleKeyPress = (e) => {
        if (e.which === 13 || e.which === 32) {
            toggleCollapse();
            e.preventDefault();
        }
    };

    return (
        <div className="sidebar-item sidebar-category">
            <div className="sidebar-item-toggle noselect" onClick={toggleCollapse} tabIndex={0} onKeyPress={handleKeyPress} id={`cat_${category._id}`}>
                <div className="toggle-title">
                    <i className={classNames("mr-2", collapsed ? "fal fa-angle-right" : "fal fa-angle-down")} style={{ minWidth: "7px" }} />
                    <i
                        className={classNames("fal fa-folder fa-sm mr-2", {
                            "text-primary": activeSnippet.category === category._id,
                        })}
                    />
                    {category.name}
                </div>
            </div>
            <div className="sidebar-item-collapse sidebar-category-collapse" style={{ display: collapsed ? "none" : "block" }}>
                {category.snippets.map((snippet) => (
                    <Snippet key={snippet._id} snippet={snippet} hide={collapsed} loadSnippet={loadSnippet} activeSnippetId={activeSnippet?._id} />
                ))}
                {category.count > category.snippets.length && <LoadMore category={category._id} subcategory="" count={category.snippets.length} />}
                {category.subcategories.map((subcategory) => (
                    <Subcategory
                        key={subcategory._id}
                        subcategory={subcategory}
                        categoryId={category._id}
                        hide={collapsed}
                        loadSnippet={loadSnippet}
                        activeSnippet={activeSnippet}
                    />
                ))}
            </div>
        </div>
    );
};

export default Category;

export const SET_THEME = "SET_THEME";
export const SET_RANDOM_THEME = "SET_RANDOM_THEME";

export const setTheme = (theme) => ({
    type: "SET_THEME",
    payload: theme,
});

export const setRandomTheme = (theme) => ({
    type: "SET_RANDOM_THEME",
    payload: theme,
});

import React from "react";
// import SplitterLayout from "react-splitter-layout";
import SplitPane from "react-split-pane";

import settings from "utils/settings";

import Editors from "./Editors";
import useSnippets from "./useSnippets";
import Sidebar from "./Sidebar";

const Snippets = () => {
    const { sidebarSize, onSidebarSizeChange, onKeyDown, loadSnippet } = useSnippets();

    return (
        <div className="snippets-page" onKeyDown={onKeyDown}>
            <SplitPane
                split="vertical"
                minSize={settings.sidebarSize.min}
                maxSize={settings.sidebarSize.max}
                size={sidebarSize}
                onChange={onSidebarSizeChange}
                className="sidebar-splitter"
            >
                <Sidebar loadSnippet={loadSnippet} />
                <Editors />
            </SplitPane>
        </div>
    );
};

export default Snippets;

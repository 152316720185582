import React from "react";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { css } from "emotion";

import CustomDropdownToggle from "components/CustomDropdownToggle";
import useSquadsDropdown from "./useSquadsDropdown";

const SquadsDropdown = () => {
    const theme = useSelector((state) => state.theme);
    const { squads, selectedSquad, onChange } = useSquadsDropdown();

    return (
        <Dropdown className="app-navigator">
            <Dropdown.Toggle as={CustomDropdownToggle}>
                <div className="app-navigator-toggle noselect">
                    <i
                        className={classNames("mr-2", { "fal fa-users": selectedSquad?.name, "fal fa-users-slash": !selectedSquad })}
                        style={{ color: theme.primary }}
                    />
                    {selectedSquad?.name || "no squad"}
                    <i className="fal fa-angle-down ml-2 dropdown-icon" />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item
                    className={classNames(
                        "d-flex",
                        { active: !selectedSquad },
                        css({
                            "&.active i": {
                                color: theme.primary,
                            },
                        })
                    )}
                    onClick={() => {
                        onChange(null);
                    }}
                >
                    <div className="mr-2 d-flex justify-content-center align-items-center" style={{ minWidth: "20px" }}>
                        <i className="fal fa-users-slash" />
                    </div>
                    no squad
                </Dropdown.Item>
                {squads.map((squad) => (
                    <Dropdown.Item
                        className={classNames(
                            "d-flex",
                            { active: squad._id === selectedSquad?._id },
                            css({
                                "&.active i": {
                                    color: theme.primary,
                                },
                            })
                        )}
                        onClick={() => {
                            onChange(squad);
                        }}
                        key={squad._id}
                    >
                        <div className="mr-2 d-flex justify-content-center align-items-center" style={{ minWidth: "20px" }}>
                            <i className="fal fa-users" />
                        </div>
                        {squad.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(SquadsDropdown);

import { DEFAULT_SIDEBAR } from "reduxStore/snippet-page/snippet-page.actions";
import settings from "utils/settings";

const SIDEBAR_KEY = "sidebar";

export const getSidebar = () => {
    try {
        return JSON.parse(localStorage.getItem(SIDEBAR_KEY));
    } catch (err) {
        return DEFAULT_SIDEBAR;
    }
};

export const setSidebar = (sidebar) => {
    return localStorage.setItem(SIDEBAR_KEY, JSON.stringify(sidebar));
};

export const resetSidebar = () => {
    setSidebar({ size: settings.sidebarSize.default });
};

import React, { useRef } from "react";
import { Form, Tooltip, Overlay } from "react-bootstrap";
import classNames from "classnames";
import { css } from "emotion";

import { Button } from "components/ReactBootstrapWrappers";
import Textarea from "components/Inputs/Textarea";
import useSquadEntry from "./useSquadEntry";
import useDoubleClickDelete from "utils/hooks/useDoubleClickDelete";
import UserSearch from "./UserSearch";
import { sortLexical } from "utils/util";

const UserEntry = ({ user, uid, theme, onChange }) => {
    const onRemove = () => onChange(user._id);

    return (
        <div className="squad-user">
            <div className="squad-user-label">
                {user.username}
                {user._id === uid ? <span style={{ color: theme.primary }}> (me)</span> : null}
            </div>
            <div className="squad-user-close" onClick={onRemove}>
                <i className="fal fa-times" />
            </div>
        </div>
    );
};

const SquadEntry = ({ squad }) => {
    const { onChange, handleKeyPress, onUpdateClicked, removeSquad, toggleShowUserSearch, newSquad, hasChanges, uid, theme, showUserSearch } = useSquadEntry({
        squad,
    });
    const { setTooltipDisabled, incrementDeleteClicks, deleteClicks, tooltipDisabled } = useDoubleClickDelete({ callback: removeSquad });

    const target = useRef(null);

    const onRemoveUser = (userId) => {
        let newUsers = newSquad.users.filter((user) => {
            return user._id !== userId;
        });
        onChange({
            target: {
                name: "users",
                value: [...newUsers],
            },
        });
    };

    const onAddUser = (newUser) => {
        let newUsers = [...newSquad.users];
        let hasUser = newUsers.some((user) => {
            return user._id === newUser._id;
        });
        if (!hasUser) {
            newUsers.push(newUser);
            onChange({
                target: {
                    name: "users",
                    value: sortLexical(newUsers, "username", false, true),
                },
            });
        }
    };

    return (
        <div className="squad-entry">
            <Form.Group className="mb-2">
                <div className="d-flex align-items-start">
                    <div className="w-100">
                        <Form.Control
                            type="text"
                            className="mb-1"
                            placeholder="name"
                            value={newSquad.name || ""}
                            name="name"
                            onChange={onChange}
                            onKeyPress={handleKeyPress}
                            data-lpignore="true"
                        />
                        <Textarea
                            className="custom-textarea mb-1"
                            placeholder="description"
                            value={newSquad.description || ""}
                            name="description"
                            onChange={onChange}
                            data-lpignore="true"
                        />

                        <div className="squad-users-wrapper">
                            {newSquad.users.map((user) => (
                                <UserEntry key={user._id} user={user} uid={uid} theme={theme} onChange={onRemoveUser} />
                            ))}
                            <Button
                                variant="outline-dark"
                                style={{ height: "35px", width: "34px", minWidth: "34px", alignItems: "center", justifyContent: "center", padding: 0 }}
                                className="flex-btn"
                                onClick={toggleShowUserSearch}
                            >
                                <i className={showUserSearch ? "fal fa-minus" : "fal fa-plus"} />
                            </Button>
                        </div>

                        {showUserSearch ? (
                            <div className="mb-1">
                                <UserSearch onChange={onAddUser} />
                            </div>
                        ) : null}
                    </div>

                    <Button
                        variant={!hasChanges || !newSquad.name ? "outline-dark" : "outline-primary"}
                        className={classNames("flex-btn ml-2", css({ "&.disabled,&:disabled": { opacity: 1 } }))}
                        disabled={!hasChanges || !newSquad.name}
                        onClick={onUpdateClicked}
                        style={{ height: "35px", width: "34px", minWidth: "34px", alignItems: "center", justifyContent: "center", padding: 0 }}
                    >
                        <i className="fal fa-pen" />
                    </Button>

                    <Button
                        variant={deleteClicks < 1 ? "outline-danger" : "warning"}
                        className="flex-btn ml-2"
                        onClick={incrementDeleteClicks}
                        style={{ height: "35px", width: "34px", minWidth: "34px", alignItems: "center", justifyContent: "center", padding: 0 }}
                        innerRef={target}
                        onMouseOver={() => setTooltipDisabled(false)}
                        onMouseLeave={() => setTooltipDisabled(true)}
                    >
                        <i className={deleteClicks < 1 ? "fal fa-times" : "fal fa-trash-alt"} />
                    </Button>

                    <Overlay target={target.current} show={deleteClicks > 0 && !tooltipDisabled} placement="top">
                        {(ttProps) => (
                            <Tooltip id={`delete-tt-${squad._id}`} {...ttProps}>
                                click again to delete.
                            </Tooltip>
                        )}
                    </Overlay>
                </div>
            </Form.Group>
        </div>
    );
};

export default React.memo(SquadEntry);

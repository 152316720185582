import React, { useRef } from "react";
import { Form, Tooltip, Overlay } from "react-bootstrap";
import classNames from "classnames";
import { css } from "emotion";

import { Button } from "components/ReactBootstrapWrappers";
import useLabelEntry from "./useLabelEntry";
import useDoubleClickDelete from "utils/hooks/useDoubleClickDelete";

const LabelEntry = ({ label }) => {
    const {
        onChange,
        handleKeyPress,
        onUpdateClicked,
        removeLabel,

        name,
    } = useLabelEntry({ label });
    const { setTooltipDisabled, incrementDeleteClicks, deleteClicks, tooltipDisabled } = useDoubleClickDelete({ callback: removeLabel });

    const target = useRef(null);

    return (
        <div className="label-entry">
            <Form.Group className="mb-2">
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="text"
                        placeholder="name"
                        value={name || ""}
                        name="name"
                        onChange={onChange}
                        onKeyPress={handleKeyPress}
                        data-lpignore="true"
                    />
                    <Button
                        variant={name === label.name || !name ? "outline-dark" : "outline-primary"}
                        className={classNames("flex-btn ml-2", css({ "&.disabled,&:disabled": { opacity: 1 } }))}
                        disabled={name === label.name || !name}
                        onClick={onUpdateClicked}
                        style={{ height: "35px", width: "34px", minWidth: "34px", alignItems: "center", justifyContent: "center", padding: 0 }}
                    >
                        <i className="fal fa-pen" />
                    </Button>

                    <Button
                        variant={deleteClicks < 1 ? "outline-danger" : "warning"}
                        className="flex-btn ml-2"
                        onClick={incrementDeleteClicks}
                        style={{ height: "35px", width: "34px", minWidth: "34px", alignItems: "center", justifyContent: "center", padding: 0 }}
                        innerRef={target}
                        onMouseOver={() => setTooltipDisabled(false)}
                        onMouseLeave={() => setTooltipDisabled(true)}
                    >
                        <i className={deleteClicks < 1 ? "fal fa-times" : "fal fa-trash-alt"} />
                    </Button>

                    <Overlay target={target.current} show={deleteClicks > 0 && !tooltipDisabled} placement="top">
                        {(ttProps) => (
                            <Tooltip id={`delete-tt-${label._id}`} {...ttProps}>
                                click again to delete.
                            </Tooltip>
                        )}
                    </Overlay>
                </div>
            </Form.Group>
        </div>
    );
};

export default React.memo(LabelEntry);

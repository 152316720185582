import { getSnippet } from "utils/api/snippets";
import { clearMetadata } from "utils/util";
import history from "utils/history";
import { postSnippet, putSnippet } from "reduxStore/snippets/snippets.actions";
import { getSidebar } from "views/Snippets/Sidebar/sidebarOptions";
import { getOptions } from "views/Snippets/Editors/editorOptions";
import settings from "utils/settings";

export const RESET_SNIPPET = "RESET_SNIPPET";

export const resetSnippet = () => ({
    type: RESET_SNIPPET,
});

export const SET_SNIPPET = "SET_SNIPPET";

export const setSnippet = (snippet) => ({
    type: SET_SNIPPET,
    payload: snippet,
});

export const SET_SNIPPET_ATTRIBUTE = "SET_SNIPPET_ATTRIBUTE";

export const setSnippetAttribute = (payload) => ({
    type: SET_SNIPPET_ATTRIBUTE,
    payload,
});

/**
 * options
 */
export const DEFAULT_OPTIONS = {
    wrapEnabled: true,
    showGutter: true,
    showPrintMargin: true,
    fontSize: 16,
    tabSize: 4,
    mode: "json",
    panelSize: isNaN(getOptions()?.panelSize) ? 450 : getOptions()?.panelSize,
};

export const SET_OPTIONS = "SET_OPTIONS";

export const setOptions = (options) => ({
    type: SET_OPTIONS,
    payload: options,
});

export const SET_OPTION = "SET_OPTION";

export const setOption = (payload) => ({
    type: SET_OPTION,
    payload,
});

/**
 * sidebar
 */
export const DEFAULT_SIDEBAR = {
    size: isNaN(getSidebar()?.size) ? settings.sidebarSize.default : getSidebar()?.size,
};

export const SET_SIDEBAR = "SET_SIDEBAR";

export const setSidebar = (options) => ({
    type: SET_SIDEBAR,
    payload: options,
});

export const SET_SIDEBAR_ATTRIBUTE = "SET_SIDEBAR_ATTRIBUTE";

export const setSidebarAttribute = (payload) => ({
    type: SET_SIDEBAR_ATTRIBUTE,
    payload,
});

export const FETCH_SNIPPET_BEGIN = "FETCH_SNIPPET_BEGIN";
export const FETCH_SNIPPET_SUCCESS = "FETCH_SNIPPET_SUCCESS";
export const FETCH_SNIPPET_FAILURE = "FETCH_SNIPPET_FAILURE";

export const fetchSnippetBegin = () => ({
    type: FETCH_SNIPPET_BEGIN,
});

export const fetchSnippetSuccess = (categories) => ({
    type: FETCH_SNIPPET_SUCCESS,
    payload: categories,
});

export const fetchSnippetFailure = (error) => ({
    type: FETCH_SNIPPET_FAILURE,
    payload: error,
});

export const fetchSnippet = ({ id }) => {
    return async (dispatch) => {
        dispatch(fetchSnippetBegin());
        try {
            const res = await getSnippet({ id });
            dispatch(fetchSnippetSuccess(res.data));
            return {
                success: true,
                snippet: res.data,
            };
        } catch (err) {
            console.error(err);
            dispatch(fetchSnippetFailure(err));
            history.push({
                pathname: "/snippets",
                search: "",
            });
            return {
                success: false,
                snippet: null,
            };
        }
    };
};

export const SET_SAVE_MODAL = "SET_SAVE_MODAL";

export const setSaveModal = (payload) => ({
    type: SET_SAVE_MODAL,
    payload,
});

export const SET_NEW_MODAL = "SET_NEW_MODAL";

export const setNewModal = (payload) => ({
    type: SET_NEW_MODAL,
    payload,
});

export const onSave = ({ snippet, options, categories, subcategories, showSaveModal }) => {
    return async (dispatch) => {
        let saveSnippet = { ...snippet, mode: options.mode };
        const errors = validate(saveSnippet);
        if (errors.length) {
            if (!showSaveModal) {
                dispatch(setSaveModal(true));
            }
            return {
                success: false,
                errors,
            };
        }

        let success;
        let type;
        try {
            if (!saveSnippet._id) {
                type = "add";
                saveSnippet._id = undefined;
                const res = await dispatch(postSnippet({ item: saveSnippet, categories, subcategories }));
                success = res.success;
                if (success) {
                    dispatch(setOption({ name: "mode", value: saveSnippet.mode }));
                    dispatch(setSnippet(res.snippet));
                    history.push({
                        pathname: "/snippets",
                        search: `?id=${res.snippet._id}`,
                    });
                }
            } else {
                type = "edit";
                const id = saveSnippet._id;
                saveSnippet = clearMetadata(saveSnippet);
                const res = await dispatch(putSnippet({ id, item: saveSnippet, categories, subcategories }));
                success = res.success;
                if (success) {
                    dispatch(setSnippet(res.snippet));
                }
            }
            if (success) {
                return {
                    success: true,
                    snippet: saveSnippet,
                    type,
                };
            }
            return {
                success: false,
                snippet: null,
                type,
            };
        } catch (err) {
            return {
                success: false,
                snippet: null,
                type,
            };
        }
    };
};

const validate = (snippet) => {
    const { name, value, category, mode } = snippet;

    let errors = [];
    if (!name) {
        errors.push("name is required");
    }

    if (!value) {
        errors.push("snippet body is required");
    }

    if (!category) {
        errors.push("category is required");
    }

    if (!mode) {
        errors.push("mode is required");
    }

    return errors;
};

export const onNew = () => {
    return (dispatch) => {
        dispatch(resetSnippet());
        history.push({
            pathname: "/snippets",
            search: "",
        });
        dispatch(setNewModal(false));
    };
};

export const SET_PRIMARY_EDITOR_REF = "SET_PRIMARY_EDITOR_REF";
export const SET_SCRATCHPAD_EDITOR_REF = "SET_SCRATCHPAD_EDITOR_REF";

export const setPrimaryEditorRef = (editorRef) => ({
    type: SET_PRIMARY_EDITOR_REF,
    payload: editorRef,
});

export const setScratchpadEditorRef = (editorRef) => ({
    type: SET_SCRATCHPAD_EDITOR_REF,
    payload: editorRef,
});

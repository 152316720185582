import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";
import { useSelector } from "react-redux";

import useReduxDataPutting from "utils/hooks/useReduxDataPutting";
import useReduxDataDeleting from "utils/hooks/useReduxDataDeleting";
import settings from "utils/settings";
import { isEqual } from "utils/lodashReplacements";
import { getUsers } from "utils/api/users";
import { putSquad, deleteSquad } from "reduxStore/squads/squads.actions";
import { sortLexical } from "utils/util";

/**
 * Strips metadata from a squad
 * @param {Object} squad
 */
const stripSquad = (squad) => {
    return { name: squad.name, description: squad.description || "", users: squad.users || [] };
};

/**
 * Converts a complex squad to a simple one by reducing the users and stripping metadata
 * @param {Object} squad
 */
const simplifySquad = (squad) => {
    return stripSquad({ ...squad, users: squad.users?.map((user) => user._id) || [] });
};

/**
 * Converts a simple squad to an expanded one by converting users from strings to objects and stripping metadata
 * @param {Object} squad
 */
const expandSquad = (squad) => {
    return stripSquad({
        ...squad,
        users: squad.users?.map((user) => {
            return {
                _id: user,
                username: "",
            };
        }),
    });
};

const useSquadEntry = ({ squad }) => {
    const { data: authData } = useSelector((state) => state.auth);
    const uid = authData.user?._id || "";
    const theme = useSelector((state) => state.theme);
    const { putData } = useReduxDataPutting(putSquad, "squads");
    const { deleteData } = useReduxDataDeleting(deleteSquad, "squads");

    const [newSquad, setNewSquad] = useState({ ...expandSquad(squad) });

    // Load the list of usernames
    useEffect(() => {
        const fetchUsers = async (userIds) => {
            try {
                const { data: users } = await getUsers(userIds);
                setNewSquad({ ...newSquad, users: sortLexical(users, "username", false, true) });
            } catch (err) {}
        };

        if (squad.users.length) {
            fetchUsers(squad.users);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [squad.users]);

    const onChange = (evt) => {
        const { name, value } = evt.target;
        setNewSquad({ ...newSquad, [name]: value });
    };

    const updateSquad = async () => {
        setNewSquad({ ...newSquad, description: newSquad.description.trim() });
        const success = await putData({ id: squad._id, item: simplifySquad({ ...newSquad, description: newSquad.description.trim() }) });
        if (success) {
            toast(`squad "${newSquad.name}" updated.`.toLowerCase());
        }
    };

    const onUpdateClicked = async () => {
        if (isEqual(simplifySquad(newSquad), stripSquad(squad))) return;
        updateSquad();
    };

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            onUpdateClicked();
        }
    };

    const removeSquad = async () => {
        const success = await deleteData(squad._id);
        if (success) {
            toast(`squad "${newSquad.name}" deleted.`.toLowerCase());
        }
    };

    const [hasChanges, setHasChanges] = useState(false);
    const [debounceHasChanges] = useDebouncedCallback((a, b) => {
        setHasChanges(!isEqual(a, b));
    }, settings.debounce.typing);

    useEffect(() => {
        debounceHasChanges(simplifySquad(newSquad), stripSquad(squad));
    }, [newSquad, squad, debounceHasChanges]);

    const [showUserSearch, setShowUserSearch] = useState(false);

    const toggleShowUserSearch = () => {
        setShowUserSearch(!showUserSearch);
    };

    return {
        // fns
        onChange,
        handleKeyPress,
        onUpdateClicked,
        removeSquad,
        toggleShowUserSearch,

        // data
        newSquad,
        hasChanges,
        uid,
        theme,
        showUserSearch,
    };
};

export default useSquadEntry;

import { getLabels, addLabel, updateLabel, removeLabel } from "utils/api/manage";

export const RESET_LABELS = "RESET_LABELS";

export const resetLabels = () => ({
    type: RESET_LABELS,
});

export const FETCH_LABELS_BEGIN = "FETCH_LABELS_BEGIN";
export const FETCH_LABELS_SUCCESS = "FETCH_LABELS_SUCCESS";
export const FETCH_LABELS_FAILURE = "FETCH_LABELS_FAILURE";

export const fetchLabelsBegin = () => ({
    type: FETCH_LABELS_BEGIN,
});

export const fetchLabelsSuccess = (labels) => ({
    type: FETCH_LABELS_SUCCESS,
    payload: labels,
});

export const fetchLabelsFailure = (error) => ({
    type: FETCH_LABELS_FAILURE,
    payload: error,
});

export const fetchLabels = () => {
    return async (dispatch) => {
        dispatch(fetchLabelsBegin());
        try {
            const res = await getLabels();
            dispatch(fetchLabelsSuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(fetchLabelsFailure(err));
            return false;
        }
    };
};

export const POST_LABEL_BEGIN = "POST_LABEL_BEGIN";
export const POST_LABEL_SUCCESS = "POST_LABEL_SUCCESS";
export const POST_LABEL_FAILURE = "POST_LABEL_FAILURE";

export const postLabelBegin = () => ({
    type: POST_LABEL_BEGIN,
});

export const postLabelSuccess = (label) => ({
    type: POST_LABEL_SUCCESS,
    payload: label,
});

export const postLabelFailure = (error) => ({
    type: POST_LABEL_FAILURE,
    payload: error,
});

export const postLabel = ({ item }) => {
    return async (dispatch) => {
        dispatch(postLabelBegin());
        try {
            const res = await addLabel({ item });
            dispatch(postLabelSuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(postLabelFailure(err));
            return false;
        }
    };
};

export const PUT_LABEL_BEGIN = "PUT_LABEL_BEGIN";
export const PUT_LABEL_SUCCESS = "PUT_LABEL_SUCCESS";
export const PUT_LABEL_FAILURE = "PUT_LABEL_FAILURE";

export const putLabelBegin = () => ({
    type: PUT_LABEL_BEGIN,
});

export const putLabelSuccess = (label) => ({
    type: PUT_LABEL_SUCCESS,
    payload: label,
});

export const putLabelFailure = (error) => ({
    type: PUT_LABEL_FAILURE,
    payload: error,
});

export const putLabel = ({ id, item }) => {
    return async (dispatch) => {
        dispatch(putLabelBegin());
        try {
            const res = await updateLabel({ id, item });
            dispatch(putLabelSuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(putLabelFailure(err));
            return false;
        }
    };
};

export const DELETE_LABEL_BEGIN = "DELETE_LABEL_BEGIN";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const DELETE_LABEL_FAILURE = "DELETE_LABEL_FAILURE";

export const deleteLabelBegin = () => ({
    type: DELETE_LABEL_BEGIN,
});

export const deleteLabelSuccess = (labelId) => ({
    type: DELETE_LABEL_SUCCESS,
    payload: labelId,
});

export const deleteLabelFailure = (error) => ({
    type: DELETE_LABEL_FAILURE,
    payload: error,
});

export const deleteLabel = ({ id }) => {
    return async (dispatch) => {
        dispatch(deleteLabelBegin());
        try {
            const res = await removeLabel({ id });
            dispatch(deleteLabelSuccess(res.data._id));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(deleteLabelFailure(err));
            return false;
        }
    };
};

import React from "react";
import { Form } from "react-bootstrap";

import { Button } from "components/ReactBootstrapWrappers";
import SquadEntry from "./SquadEntry";
import SquadEntryDisplay from "./SquadEntryDisplay";
import useSquads from "./useSquads";

const Squads = () => {
    const { onChange, onAddClicked, onKeyPress, mySquads, squadsImIn, squad } = useSquads();

    return (
        <div className="content-page">
            <div className="page-content">
                <div className="page-wrapper">
                    <div className="page-section">
                        <h4>my squads</h4>
                        <div className="page-group pt-2">
                            <Form.Group>
                                <div className="d-flex align-items-center">
                                    <Form.Control
                                        type="text"
                                        placeholder="name"
                                        value={squad.name || ""}
                                        name="name"
                                        onChange={onChange}
                                        onKeyPress={onKeyPress}
                                        data-lpignore="true"
                                    />
                                    <Button variant="outline-primary" className="flex-btn ml-2" disabled={!squad.name} onClick={onAddClicked}>
                                        <i className="fal fa-plus mr-2" />
                                        add
                                    </Button>
                                </div>
                            </Form.Group>
                            {mySquads.map((squad) => (
                                <SquadEntry key={squad._id} squad={squad} />
                            ))}
                        </div>
                    </div>
                    {squadsImIn.length > 0 && (
                        <div className="page-section">
                            <h4>squads i'm in</h4>
                            <div className="page-group pt-2">
                                {squadsImIn.map((squad) => (
                                    <SquadEntryDisplay key={squad._id} squad={squad} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Squads;

import { login, register, verify } from "utils/api/auth";
import apiClient from "utils/api/axios";
import { authenticateUser, deauthenticateUser } from "utils/hooks/useAuth";

export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const clearLoginError = () => ({
    type: CLEAR_LOGIN_ERROR,
});

export const loginBegin = () => ({
    type: LOGIN_BEGIN,
});

export const loginSuccess = (userData) => ({
    type: LOGIN_SUCCESS,
    payload: userData,
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});

export const signIn = ({ username, password }) => {
    return async (dispatch) => {
        dispatch(loginBegin());
        try {
            const res = await login({ username, password });
            apiClient.defaults.headers.common["Authorization"] = `JWT ${res.data.token}`;
            authenticateUser(res.data.token);
            dispatch(loginSuccess({ user: res.data.user, auth: res.data.auth }));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(loginFailure(err));
            return false;
        }
    };
};

export const VERIFY_BEGIN = "VERIFY_BEGIN";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILURE = "VERIFY_FAILURE";

export const verifyBegin = () => ({
    type: VERIFY_BEGIN,
});

export const verifySuccess = (userData) => ({
    type: VERIFY_SUCCESS,
    payload: userData,
});

export const verifyFailure = (error) => ({
    type: VERIFY_FAILURE,
    payload: error,
});

export const verifyToken = ({ token }) => {
    return async (dispatch) => {
        dispatch(verifyBegin());
        try {
            apiClient.defaults.headers.common["Authorization"] = `JWT ${token}`;
            const res = await verify();
            dispatch(verifySuccess({ user: res.data.user, auth: res.data.auth }));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(verifyFailure(err));
            return false;
        }
    };
};

export const REGISTER_BEGIN = "REGISTER_BEGIN";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const CLEAR_REGISTER_ERROR = "CLEAR_REGISTER_ERROR";

export const clearRegisterError = () => ({
    type: CLEAR_REGISTER_ERROR,
});

export const registerBegin = () => ({
    type: REGISTER_BEGIN,
});

export const registerSuccess = () => ({
    type: REGISTER_SUCCESS,
});

export const registerFailure = (error) => ({
    type: REGISTER_FAILURE,
    payload: error,
});

export const signUp = ({ username, email, password }) => {
    return async (dispatch) => {
        dispatch(registerBegin());
        try {
            await register({ username, email, password });
            dispatch(registerSuccess());
            return true;
        } catch (err) {
            console.error(err);
            dispatch(registerFailure(err));
            return false;
        }
    };
};

export const DEAUTHENTICATE = "DEAUTHENTICATE";

export const logout = () => {
    return (dispatch) => {
        dispatch(deauthenticate());
        deauthenticateUser();
    };
};

export const deauthenticate = () => ({
    type: DEAUTHENTICATE,
});

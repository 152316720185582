import React, { forwardRef } from "react";
import classNames from "classnames";

import useEditor from "./useEditor";

const Editor = forwardRef((props, ref) => {
    const { className, style, ...rest } = props;
    const { uniqueId } = useEditor(rest, ref);

    return <div id={`editor-${uniqueId}`} className={classNames(className, "editor")} style={style} />;
});

export default Editor;

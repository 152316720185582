import React, { useRef, useEffect } from "react";
import { Form } from "react-bootstrap";

const Textarea = (props) => {
    const { style, ...rest } = props;
    const textareaRef = useRef(null);

    useEffect(() => {
        calcHeight();
    }, []);

    useEffect(() => {
        calcHeight();
    }, [props.value]);

    const calcHeight = () => {
        textareaRef.current.style.height = "";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`;
    };

    return <Form.Control as="textarea" ref={textareaRef} style={{ ...style, resize: "none", overflow: "hidden" }} {...rest} />;
};

export default Textarea;

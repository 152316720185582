import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useSelector, useDispatch } from "react-redux";

import settings from "utils/settings";
import { setOptions, DEFAULT_OPTIONS, setOption } from "reduxStore/snippet-page/snippet-page.actions";

import { getOptions } from "./editorOptions";

const useEditors = () => {
    const { options, primaryEditorRef, scratchpadEditorRef } = useSelector((state) => state.snippetPage);
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            let storageOptions = getOptions();
            let mergedOptions = mergeOptions({ storageOptions, localOptions: DEFAULT_OPTIONS });
            dispatch(setOptions(mergedOptions));
        } catch {}
    }, [dispatch]);

    const mergeOptions = ({ storageOptions, localOptions }) => {
        let mergedOptions = {};
        Object.keys(localOptions).forEach((k) => {
            const v = localOptions[k];
            if (storageOptions?.[k] !== undefined) {
                mergedOptions[k] = storageOptions[k];
            } else mergedOptions[k] = v;
        });
        return mergedOptions;
    };

    const [debounceSetPanelSize] = useDebouncedCallback((size) => {
        dispatch(setOption({ name: "panelSize", value: size }));
        forceResize();
    }, settings.debounce.typing);

    const onEditorSizeChange = (size) => {
        debounceSetPanelSize(size);
    };

    const forceResize = () => {
        primaryEditorRef?.current && primaryEditorRef.current.forceResize();
        scratchpadEditorRef?.current && scratchpadEditorRef.current.forceResize();
    };

    return {
        options,
        onEditorSizeChange,
    };
};

export default useEditors;

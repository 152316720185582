import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { injectGlobal } from "emotion";
import { useDebouncedCallback } from "use-debounce";

import { verifyToken } from "reduxStore/auth/auth.actions";
import { fetchSettings } from "reduxStore/settings/settings.actions";
import { fetchSquads } from "reduxStore/squads/squads.actions";
import useAuth, { getSquadLocal } from "utils/hooks/useAuth";
import { toggleContextMenuOff } from "reduxStore/context-menu/context-menu.actions";
import useReduxDataFetching from "utils/hooks/useReduxDataFetching";
import settings from "utils/settings";

const useMain = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { getToken, auth, logout } = useAuth();
    useReduxDataFetching(fetchSettings, "settings");

    // Auth
    useEffect(() => {
        if (auth !== true) {
            const token = getToken();
            if (token !== null) {
                verify(token);
            } else {
                history.push("/login");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const verify = async (token) => {
        const success = await dispatch(verifyToken({ token }));
        if (!success) {
            logout();
            window.location.href = window.location.origin;
        }
    };

    // Theme
    const theme = useSelector((state) => state.theme);

    useEffect(() => {
        injectGlobal`
            a {
                color: ${theme.primary};
                &:hover {
                    color: ${theme["primary-dark-1"]};
                }
            }
            .text-primary {
                color: ${theme.primary} !important;
            }
        `;
    }, [theme]);

    // Squads
    const { squad, isFetching: fetchingSquads } = useSelector((state) => state.squads);

    useEffect(() => {
        if (auth === true) {
            const squadId = getSquadLocal();
            dispatch(fetchSquads(squadId));
        }
    }, [auth, dispatch]);

    const [debounceCloseContextMenus] = useDebouncedCallback(() => {
        dispatch(toggleContextMenuOff("sidebarSnippet"));
    }, settings.debounce.typing);

    // unset default key events
    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if ((e.ctrlKey && e.shiftKey && e.which === 83) || (e.ctrlKey && e.which === 83) || (e.ctrlKey && e.shiftKey && e.which === 78)) {
                e.preventDefault();
            }
        });
        window.onresize = () => {
            debounceCloseContextMenus();
        };
    }, [debounceCloseContextMenus]);

    return {
        auth,
        fetchingSquads,
        squad,
    };
};

export default useMain;

import { useState } from "react";
import { toast } from "react-toastify";

import useReduxDataPutting from "utils/hooks/useReduxDataPutting";
import useReduxDataDeleting from "utils/hooks/useReduxDataDeleting";
import { putSubcategory, deleteSubcategory } from "reduxStore/subcategories/subcategories.actions";

const useSubcategoryEntry = ({ subcategory }) => {
    const { putData } = useReduxDataPutting(putSubcategory, "categories");
    const { deleteData } = useReduxDataDeleting(deleteSubcategory, "categories");

    const [name, setName] = useState(subcategory.name);

    const onChange = (evt) => {
        const { value } = evt.target;
        setName(value);
    };

    const updateSubcategory = async () => {
        const success = await putData({ id: subcategory._id, item: { name } });
        if (success) {
            toast(`subcategory "${name}" updated.`.toLowerCase());
        }
    };

    const onUpdateClicked = async () => {
        if (name === subcategory.name || !name) return;
        updateSubcategory();
    };

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            onUpdateClicked();
        }
    };

    const removeSubcategory = async () => {
        const success = await deleteData(subcategory._id);
        if (success) {
            toast(`subcategory "${name}" deleted.`.toLowerCase());
        }
    };

    return {
        // fns
        onChange,
        handleKeyPress,
        onUpdateClicked,
        removeSubcategory,

        // data
        name,
    };
};

export default useSubcategoryEntry;

import React, { useRef } from "react";
import { Form, Tooltip, Overlay } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import useDoubleClickDelete from "utils/hooks/useDoubleClickDelete";
import { leaveSquad } from "reduxStore/squads/squads.actions";

import { Button } from "components/ReactBootstrapWrappers";

const SquadEntry = ({ squad }) => {
    const dispatch = useDispatch();

    const abandonSquad = async () => {
        const success = await dispatch(leaveSquad({ id: squad._id }));
        if (success) {
            toast(`left squad "${squad.name}".`.toLowerCase());
        }
    };

    const { setTooltipDisabled, incrementDeleteClicks, deleteClicks, tooltipDisabled } = useDoubleClickDelete({ callback: abandonSquad });
    const target = useRef(null);

    return (
        <div className="squad-entry display">
            <Form.Group className="mb-2">
                <div className="d-flex align-items-center">
                    <div className="squad-name w-100">{squad.name}</div>

                    <Button
                        variant={deleteClicks < 1 ? "outline-danger" : "warning"}
                        className="flex-btn ml-2"
                        onClick={incrementDeleteClicks}
                        style={{ height: "35px", width: "34px", minWidth: "34px", alignItems: "center", justifyContent: "center", padding: 0 }}
                        innerRef={target}
                        onMouseOver={() => setTooltipDisabled(false)}
                        onMouseLeave={() => setTooltipDisabled(true)}
                    >
                        <i className={deleteClicks < 1 ? "fal fa-times" : "fal fa-trash-alt"} />
                    </Button>

                    <Overlay target={target.current} show={deleteClicks > 0 && !tooltipDisabled} placement="top">
                        {(ttProps) => (
                            <Tooltip id={`delete-tt-${squad._id}`} {...ttProps}>
                                click again to leave squad.
                            </Tooltip>
                        )}
                    </Overlay>
                </div>
            </Form.Group>
        </div>
    );
};

export default React.memo(SquadEntry);

import client from "./axios";

export const getUsers = (userIds) => {
    return client({
        method: "get",
        url: "/users",
        params: { userIds },
    });
};

export const findUsers = (search) => {
    return client({
        method: "get",
        url: "/users/find",
        params: { search },
    });
};

import React from "react";
import { Form } from "react-bootstrap";

import { Button } from "components/ReactBootstrapWrappers";
import LabelEntry from "./LabelEntry";
import useLabels from "./useLabels";

const Labels = () => {
    const { onChange, onAddClicked, onKeyPress, labels, label } = useLabels();

    return (
        <div className="page-section">
            <h4>labels</h4>
            <div className="page-group pt-2">
                <Form.Group>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            placeholder="name"
                            value={label.name || ""}
                            name="name"
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            data-lpignore="true"
                        />
                        <Button variant="outline-primary" className="flex-btn ml-2" disabled={!label.name} onClick={onAddClicked}>
                            <i className="fal fa-plus mr-2" />
                            add
                        </Button>
                    </div>
                </Form.Group>
                {labels.map((label) => (
                    <LabelEntry key={label._id} label={label} />
                ))}
            </div>
        </div>
    );
};

export default React.memo(Labels);

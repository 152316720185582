import { getSettings, updateSettings } from "utils/api/settings";
import { setTheme } from "reduxStore/theme/theme.actions";

export const FETCH_SETTINGS_BEGIN = "FETCH_SETTINGS_BEGIN";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";

export const fetchSettingsBegin = () => ({
    type: FETCH_SETTINGS_BEGIN,
});

export const fetchSettingsSuccess = (settings) => ({
    type: FETCH_SETTINGS_SUCCESS,
    payload: settings,
});

export const fetchSettingsFailure = (error) => ({
    type: FETCH_SETTINGS_FAILURE,
    payload: error,
});

export const fetchSettings = () => {
    return async (dispatch) => {
        dispatch(fetchSettingsBegin());
        try {
            const res = await getSettings();
            dispatch(fetchSettingsSuccess(res.data));
            if (res.data.themeColour) {
                dispatch(setTheme({ primary: res.data.themeColour }));
            }
            return true;
        } catch (err) {
            console.error(err);
            dispatch(fetchSettingsFailure(err));
            return false;
        }
    };
};

export const POST_SETTINGS_BEGIN = "POST_SETTINGS_BEGIN";
export const POST_SETTINGS_SUCCESS = "POST_SETTINGS_SUCCESS";
export const POST_SETTINGS_FAILURE = "POST_SETTINGS_FAILURE";

export const postSettingsBegin = () => ({
    type: POST_SETTINGS_BEGIN,
});

export const postSettingsSuccess = (settings) => ({
    type: POST_SETTINGS_SUCCESS,
    payload: settings,
});

export const postSettingsFailure = (error) => ({
    type: POST_SETTINGS_FAILURE,
    payload: error,
});

export const postSettings = ({ item }) => {
    return async (dispatch) => {
        dispatch(postSettingsBegin());
        try {
            const res = await updateSettings({ item });
            dispatch(postSettingsSuccess(res.data));
            if (res.data.themeColour) {
                dispatch(setTheme({ primary: res.data.themeColour }));
            }
            return true;
        } catch (err) {
            console.error(err);
            dispatch(postSettingsFailure(err));
            return false;
        }
    };
};

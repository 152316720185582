import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { signUp, clearRegisterError } from "reduxStore/auth/auth.actions";
import { validateEmail } from "utils/util";

const USERNAME_LENGTH = {
    min: 4,
    max: 20,
};

const PASSWORD_LENGTH = {
    min: 6,
    max: 36,
};

const useRegister = () => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const { registerError } = useSelector((state) => state.auth);
    const theme = useSelector((state) => state.theme);

    useEffect(() => {
        return () => {
            dispatch(clearRegisterError());
        };
    }, [dispatch]);

    useEffect(() => {
        if (registerError) {
            if (registerError?.response?.data?.message) {
                setErrorMsg(`registration error: ${registerError.response.data.message.toLowerCase()}`);
            } else {
                setErrorMsg("registration error: an unknown error has occurred.");
            }
        }
    }, [registerError]);

    const onChange = (evt) => {
        const { value, name } = evt.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = async () => {
        const errors = validate(formData);
        setErrors(errors);
        if (errors.length) {
            return;
        }

        const success = await dispatch(signUp(formData));
        if (success) {
            toast.success(`user ${formData.username} created successfully; please log in`);
            history.push("/login");
        }
    };

    const hideError = () => {
        setErrorMsg(null);
    };

    const hideErrors = () => {
        setErrors([]);
    };

    const onKeyPress = (e) => {
        if (e.which === 13) {
            onSubmit();
        }
    };

    const validate = (formData) => {
        const { username, email, password } = formData;

        let errors = [];
        let usernameRegex = /^[a-zA-Z0-9]{4,20}$/;
        if (!username) {
            errors.push("username is required");
        } else if (username.length < USERNAME_LENGTH.min || username.length > USERNAME_LENGTH.max) {
            errors.push(`username must be between ${USERNAME_LENGTH.min} and ${USERNAME_LENGTH.max} characters`);
        } else if (!usernameRegex.test(username)) {
            errors.push(`username must only contain alphanumeric characters`);
        }

        if (!email) {
            errors.push("email is required");
        } else if (!validateEmail(email)) {
            errors.push("email is invalid");
        }

        if (!password) {
            errors.push("password is required");
        } else if (password.length < PASSWORD_LENGTH.min || password.length > PASSWORD_LENGTH.max) {
            errors.push(`password must be between ${PASSWORD_LENGTH.min} and ${PASSWORD_LENGTH.max} characters`);
        }
        return errors;
    };
    return {
        // fns
        onChange,
        onKeyPress,
        onSubmit,
        hideError,
        hideErrors,

        // data
        formData,
        errors,
        errorMsg,
        theme,
    };
};

export default useRegister;

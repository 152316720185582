import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import reducers from "reduxStore/index";
import { DEAUTHENTICATE } from "reduxStore/auth/auth.actions";

const middlewares = [thunk];

// Create a middleware to catch the DEAUTHENTICATE action, which completely resets state
const appReducer = reducers;
const rootReducer = (state, action) => {
    if (action.type === DEAUTHENTICATE) {
        state = undefined;
    }
    return appReducer(state, action);
};

let enhancers;
if (process.env.NODE_ENV === "development") {
    enhancers = composeWithDevTools({
        trace: true,
    })(applyMiddleware(...middlewares));
} else {
    enhancers = applyMiddleware(...middlewares);
}

const store = createStore(rootReducer, enhancers);
export default store;

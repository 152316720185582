import { getSubcategories, addSubcategory, updateSubcategory, removeSubcategory } from "utils/api/manage";

export const RESET_SUBCATEGORIES = "RESET_SUBCATEGORIES";

export const resetSubcategories = () => ({
    type: RESET_SUBCATEGORIES,
});

export const FETCH_SUBCATEGORIES_BEGIN = "FETCH_SUBCATEGORIES_BEGIN";
export const FETCH_SUBCATEGORIES_SUCCESS = "FETCH_SUBCATEGORIES_SUCCESS";
export const FETCH_SUBCATEGORIES_FAILURE = "FETCH_SUBCATEGORIES_FAILURE";

export const fetchSubcategoriesBegin = () => ({
    type: FETCH_SUBCATEGORIES_BEGIN,
});

export const fetchSubcategoriesSuccess = (subcategories) => ({
    type: FETCH_SUBCATEGORIES_SUCCESS,
    payload: subcategories,
});

export const fetchSubcategoriesFailure = (error) => ({
    type: FETCH_SUBCATEGORIES_FAILURE,
    payload: error,
});

export const fetchSubcategories = () => {
    return async (dispatch) => {
        dispatch(fetchSubcategoriesBegin());
        try {
            const res = await getSubcategories();
            dispatch(fetchSubcategoriesSuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(fetchSubcategoriesFailure(err));
            return false;
        }
    };
};

export const POST_SUBCATEGORY_BEGIN = "POST_SUBCATEGORY_BEGIN";
export const POST_SUBCATEGORY_SUCCESS = "POST_SUBCATEGORY_SUCCESS";
export const POST_SUBCATEGORY_FAILURE = "POST_SUBCATEGORY_FAILURE";

export const postSubcategoryBegin = () => ({
    type: POST_SUBCATEGORY_BEGIN,
});

export const postSubcategorySuccess = (subcategory) => ({
    type: POST_SUBCATEGORY_SUCCESS,
    payload: subcategory,
});

export const postSubcategoryFailure = (error) => ({
    type: POST_SUBCATEGORY_FAILURE,
    payload: error,
});

export const postSubcategory = ({ item }) => {
    return async (dispatch) => {
        dispatch(postSubcategoryBegin());
        try {
            const res = await addSubcategory({ item });
            dispatch(postSubcategorySuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(postSubcategoryFailure(err));
            return false;
        }
    };
};

export const PUT_SUBCATEGORY_BEGIN = "PUT_SUBCATEGORY_BEGIN";
export const PUT_SUBCATEGORY_SUCCESS = "PUT_SUBCATEGORY_SUCCESS";
export const PUT_SUBCATEGORY_FAILURE = "PUT_SUBCATEGORY_FAILURE";

export const putSubcategoryBegin = () => ({
    type: PUT_SUBCATEGORY_BEGIN,
});

export const putSubcategorySuccess = (subcategory) => ({
    type: PUT_SUBCATEGORY_SUCCESS,
    payload: subcategory,
});

export const putSubcategoryFailure = (error) => ({
    type: PUT_SUBCATEGORY_FAILURE,
    payload: error,
});

export const putSubcategory = ({ id, item }) => {
    return async (dispatch) => {
        dispatch(putSubcategoryBegin());
        try {
            const res = await updateSubcategory({ id, item });
            dispatch(putSubcategorySuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(putSubcategoryFailure(err));
            return false;
        }
    };
};

export const DELETE_SUBCATEGORY_BEGIN = "DELETE_SUBCATEGORY_BEGIN";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAILURE = "DELETE_SUBCATEGORY_FAILURE";

export const deleteSubcategoryBegin = () => ({
    type: DELETE_SUBCATEGORY_BEGIN,
});

export const deleteSubcategorySuccess = (subcategoryId) => ({
    type: DELETE_SUBCATEGORY_SUCCESS,
    payload: subcategoryId,
});

export const deleteSubcategoryFailure = (error) => ({
    type: DELETE_SUBCATEGORY_FAILURE,
    payload: error,
});

export const deleteSubcategory = ({ id }) => {
    return async (dispatch) => {
        dispatch(deleteSubcategoryBegin());
        try {
            const res = await removeSubcategory({ id });
            dispatch(deleteSubcategorySuccess(res.data._id));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(deleteSubcategoryFailure(err));
            return false;
        }
    };
};

import { getCategories, addCategory, updateCategory, removeCategory } from "utils/api/manage";

export const RESET_CATEGORIES = "RESET_CATEGORIES";

export const resetCategories = () => ({
    type: RESET_CATEGORIES,
});

export const FETCH_CATEGORIES_BEGIN = "FETCH_CATEGORIES_BEGIN";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const fetchCategoriesBegin = () => ({
    type: FETCH_CATEGORIES_BEGIN,
});

export const fetchCategoriesSuccess = (categories) => ({
    type: FETCH_CATEGORIES_SUCCESS,
    payload: categories,
});

export const fetchCategoriesFailure = (error) => ({
    type: FETCH_CATEGORIES_FAILURE,
    payload: error,
});

export const fetchCategories = () => {
    return async (dispatch) => {
        dispatch(fetchCategoriesBegin());
        try {
            const res = await getCategories();
            dispatch(fetchCategoriesSuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(fetchCategoriesFailure(err));
            return false;
        }
    };
};

export const POST_CATEGORY_BEGIN = "POST_CATEGORY_BEGIN";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_FAILURE = "POST_CATEGORY_FAILURE";

export const postCategoryBegin = () => ({
    type: POST_CATEGORY_BEGIN,
});

export const postCategorySuccess = (category) => ({
    type: POST_CATEGORY_SUCCESS,
    payload: category,
});

export const postCategoryFailure = (error) => ({
    type: POST_CATEGORY_FAILURE,
    payload: error,
});

export const postCategory = ({ item }) => {
    return async (dispatch) => {
        dispatch(postCategoryBegin());
        try {
            const res = await addCategory({ item });
            dispatch(postCategorySuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(postCategoryFailure(err));
            return false;
        }
    };
};

export const PUT_CATEGORY_BEGIN = "PUT_CATEGORY_BEGIN";
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS";
export const PUT_CATEGORY_FAILURE = "PUT_CATEGORY_FAILURE";

export const putCategoryBegin = () => ({
    type: PUT_CATEGORY_BEGIN,
});

export const putCategorySuccess = (category) => ({
    type: PUT_CATEGORY_SUCCESS,
    payload: category,
});

export const putCategoryFailure = (error) => ({
    type: PUT_CATEGORY_FAILURE,
    payload: error,
});

export const putCategory = ({ id, item }) => {
    return async (dispatch) => {
        dispatch(putCategoryBegin());
        try {
            const res = await updateCategory({ id, item });
            dispatch(putCategorySuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(putCategoryFailure(err));
            return false;
        }
    };
};

export const DELETE_CATEGORY_BEGIN = "DELETE_CATEGORY_BEGIN";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export const deleteCategoryBegin = () => ({
    type: DELETE_CATEGORY_BEGIN,
});

export const deleteCategorySuccess = (categoryId) => ({
    type: DELETE_CATEGORY_SUCCESS,
    payload: categoryId,
});

export const deleteCategoryFailure = (error) => ({
    type: DELETE_CATEGORY_FAILURE,
    payload: error,
});

export const deleteCategory = ({ id }) => {
    return async (dispatch) => {
        dispatch(deleteCategoryBegin());
        try {
            const res = await removeCategory({ id });
            dispatch(deleteCategorySuccess(res.data._id));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(deleteCategoryFailure(err));
            return false;
        }
    };
};

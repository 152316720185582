import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import useReduxDataFetching from "utils/hooks/useReduxDataFetching";
import useReduxDataPosting from "utils/hooks/useReduxDataPosting";
import { fetchSubcategories, postSubcategory } from "reduxStore/subcategories/subcategories.actions";

const useSubcategories = () => {
    const { data: subcategories, fetchError } = useReduxDataFetching(fetchSubcategories, "subcategories");
    const { postData, postError } = useReduxDataPosting(postSubcategory, "subcategories");
    const { putError, deleteError } = useSelector((state) => state.subcategories);

    useEffect(() => {
        if (fetchError) {
            toast.error("an unknown error has occurred when getting subcategories.");
        }
        if (postError) {
            if (postError?.response?.data?.message === "duplicate key error") {
                toast.error("error: a subcategory with that name already exists.");
            } else {
                toast.error("an unknown error has occurred when adding a subcategory.");
            }
        }
        if (putError) {
            if (putError?.response?.data?.message === "duplicate key error") {
                toast.error("error: a subcategory with that name already exists.");
            } else {
                toast.error("an unknown error has occurred when updating a subcategory.");
            }
        }
        if (deleteError) {
            toast.error("an unknown error has occurred when deleting a subcategory.");
        }
    }, [fetchError, postError, putError, deleteError]);

    const [subcategory, setSubcategory] = useState({});

    const onChange = (evt) => {
        const { value, name } = evt.target;
        setSubcategory({ ...subcategory, [name]: value });
    };

    const addSubcategory = async () => {
        const success = await postData(subcategory);
        if (success) {
            toast.success(`subcategory "${subcategory.name}" added.`.toLowerCase());
            setSubcategory({});
        }
    };

    const onKeyPress = (e) => {
        if (e.which === 13) {
            onAddClicked();
        }
    };

    const onAddClicked = async () => {
        if (!subcategory.name) return;
        addSubcategory();
    };

    return {
        // fns
        onChange,
        onAddClicked,
        onKeyPress,

        // data
        subcategories,
        subcategory,
    };
};

export default useSubcategories;

import React from "react";
import { Form, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { css } from "emotion";

import { appName } from "utils/constants";

import { Button } from "components/ReactBootstrapWrappers";
import useRegister from "./useRegister";

const Register = () => {
    const { onChange, onKeyPress, onSubmit, hideError, hideErrors, formData, errors, errorMsg, theme } = useRegister();

    return (
        <div className="content-page">
            <div className="page-content">
                <div className="page-wrapper d-flex align-items-center justify-content-center">
                    <div className="w-100" style={{ maxWidth: "400px" }}>
                        <div className="d-flex align-items-center mb-1" style={{ fontSize: "1.25rem" }}>
                            register://{appName.toLowerCase()}
                            <div className="blinking-cursor" />
                        </div>
                        {errors.length !== 0 && (
                            <Alert variant="danger" dismissible={true} onClose={hideErrors} className="mb-2">
                                <div className="custom-close" onClick={hideErrors}>
                                    <i className="fal fa-times" />
                                </div>
                                <div>errors:</div>
                                {errors.map((err, i) => (
                                    <div key={i}>
                                        {">"} {err}
                                    </div>
                                ))}
                            </Alert>
                        )}
                        {errorMsg !== null && (
                            <Alert variant="danger" dismissible={true} onClose={hideError} className="mb-2">
                                <div className="custom-close" onClick={hideError}>
                                    <i className="fal fa-times" />
                                </div>
                                {errorMsg}
                            </Alert>
                        )}
                        <Form.Group controlId="theme-colour" className="mb-2">
                            <Form.Control
                                className="mb-2"
                                type="text"
                                placeholder="username"
                                value={formData.username || ""}
                                name="username"
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                                autoFocus
                            />
                            <Form.Control
                                className="mb-2"
                                type="text"
                                placeholder="email"
                                value={formData.email || ""}
                                name="email"
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                            <Form.Control
                                className="mb-2"
                                type="password"
                                placeholder="password"
                                value={formData.password || ""}
                                name="password"
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                            <Button variant="outline-primary" className="w-100" onClick={onSubmit}>
                                register
                            </Button>
                        </Form.Group>
                        <div className="text-muted">
                            already have an account?{" "}
                            <Link
                                to="/login"
                                className={css({
                                    color: "#676767",
                                    "&:hover, &:focus, &:active": {
                                        color: theme.primary,
                                    },
                                })}
                            >
                                log in
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;

import { sortLexical } from "utils/util";
import {
    RESET_SNIPPETS,
    FETCH_SNIPPETS_BEGIN,
    FETCH_SNIPPETS_SUCCESS,
    FETCH_SNIPPETS_FAILURE,
    FETCH_MORE_SNIPPETS_BEGIN,
    FETCH_MORE_SNIPPETS_SUCCESS,
    FETCH_MORE_SNIPPETS_FAILURE,
    POST_SNIPPET_BEGIN,
    POST_SNIPPET_SUCCESS,
    POST_SNIPPET_FAILURE,
    PUT_SNIPPET_BEGIN,
    PUT_SNIPPET_SUCCESS,
    PUT_SNIPPET_FAILURE,
    DELETE_SNIPPET_BEGIN,
    DELETE_SNIPPET_SUCCESS,
    DELETE_SNIPPET_FAILURE,
} from "./snippets.actions";

const initialState = {
    data: [],
    rawData: [],

    hasFetched: false,
    isFetching: false,
    fetchError: null,

    isPosting: false,
    postError: null,

    isPutting: false,
    putError: null,

    isDeleting: false,
    deleteError: null,
};

export default function snippetsReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_SNIPPETS:
            return initialState;
        case FETCH_SNIPPETS_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchError: null,
            };
        case FETCH_SNIPPETS_SUCCESS:
            let f_snippets = action.payload.snippets;
            let f_categories = action.payload.categories;
            let f_subcategories = action.payload.subcategories;

            const f_newSnippets = convertRawToStructured({ snippets: f_snippets, categories: f_categories, subcategories: f_subcategories });

            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                data: f_newSnippets,
                rawData: action.payload.snippets,
            };
        case FETCH_SNIPPETS_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload,
                data: [],
            };
        case FETCH_MORE_SNIPPETS_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchError: null,
            };
        case FETCH_MORE_SNIPPETS_SUCCESS:
            let fm_snippets = action.payload.snippets;
            let fm_category = action.payload.category;
            let fm_subcategory = action.payload.subcategory;

            // insert snippets into raw snippet data
            let fm_rawData = state.rawData;
            state.rawData.forEach((snippetGroup, i) => {
                if (snippetGroup.group.category === fm_category && snippetGroup.group.subcategory === fm_subcategory) {
                    fm_rawData[i].snippets = fm_rawData[i].snippets.concat(fm_snippets);
                }
            });

            let fm_newSnippets = state.data;
            state.data.forEach((snippetCategory, i) => {
                if (snippetCategory._id === fm_category) {
                    // no subcategory, so add to category.snippets
                    if (!fm_subcategory) {
                        fm_newSnippets[i].snippets = fm_newSnippets[i].snippets.concat(fm_snippets);
                    } else {
                        snippetCategory.subcategories.forEach((snippetSubcategory, j) => {
                            if (snippetSubcategory._id === fm_subcategory) {
                                fm_newSnippets[i].subcategories[j].snippets = fm_newSnippets[i].subcategories[j].snippets.concat(fm_snippets);
                            }
                        });
                    }
                }
            });

            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                data: fm_newSnippets,
                rawData: fm_rawData,
            };
        case FETCH_MORE_SNIPPETS_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload,
                data: state.data,
                rawData: state.rawData,
            };
        case POST_SNIPPET_BEGIN:
            return {
                ...state,
                isPosting: true,
                postError: null,
            };
        case POST_SNIPPET_SUCCESS:
            let p_snippet = action.payload.snippet;
            let p_categories = action.payload.categories;
            let p_subcategories = action.payload.subcategories;

            let p_rawData = state.rawData;
            let p_added = false;
            state.rawData.forEach((snippetGroup, i) => {
                if (snippetGroup.group.category === p_snippet.category && snippetGroup.group.subcategory === p_snippet.subcategory) {
                    p_rawData[i].count += 1;
                    p_rawData[i].snippets = [p_snippet, ...snippetGroup.snippets];
                    p_added = true;
                }
            });
            if (!p_added) {
                p_rawData.push({
                    count: 1,
                    snippets: [p_snippet],
                    group: {
                        category: p_snippet.category,
                        subcategory: p_snippet.subcategory,
                    },
                });
            }

            const p_snippets = convertRawToStructured({ snippets: p_rawData, categories: p_categories, subcategories: p_subcategories });

            return {
                ...state,
                isPosting: false,
                data: p_snippets,
                rawData: p_rawData,
            };
        case POST_SNIPPET_FAILURE:
            return {
                ...state,
                isPosting: false,
                postError: action.payload,
            };

        case PUT_SNIPPET_BEGIN:
            return {
                ...state,
                isPutting: true,
                putError: null,
            };
        case PUT_SNIPPET_SUCCESS:
            let u_snippet = action.payload.snippet;
            let u_categories = action.payload.categories;
            let u_subcategories = action.payload.subcategories;

            // remove the old snippet from the raw snippets
            let u_newRawSnippets = [];
            let removed = false;
            state.rawData.forEach((snippetGroup) => {
                let newSnippets = snippetGroup.snippets.filter((snippet) => {
                    if (snippet._id === u_snippet._id) {
                        removed = true;
                        return false;
                    }
                    return true;
                });
                if (newSnippets.length) {
                    u_newRawSnippets.push({ ...snippetGroup, snippets: newSnippets, count: snippetGroup.count || 1 });
                }
            });
            if (!removed) {
                return {
                    ...state,
                    isPutting: false,
                    data: state.data,
                    rawData: state.rawData,
                };
            }

            // add the new snippet to the raw snippets
            let u_newRawSnippetsAdded = [...u_newRawSnippets];
            let u_added = false;
            u_newRawSnippets.forEach((snippetGroup, i) => {
                if (snippetGroup.group.category === u_snippet.category && snippetGroup.group.subcategory === u_snippet.subcategory) {
                    u_newRawSnippetsAdded[i].snippets = sortLexical([u_snippet, ...snippetGroup.snippets], "createdAt", true);
                    u_added = true;
                }
            });
            if (!u_added) {
                u_newRawSnippetsAdded.push({
                    count: 1,
                    snippets: [u_snippet],
                    group: {
                        category: u_snippet.category,
                        subcategory: u_snippet.subcategory,
                    },
                });
            }

            const u_newSnippets = convertRawToStructured({ snippets: u_newRawSnippetsAdded, categories: u_categories, subcategories: u_subcategories });

            return {
                ...state,
                isPutting: false,
                data: u_newSnippets,
                rawData: u_newRawSnippets,
            };
        case PUT_SNIPPET_FAILURE:
            return {
                ...state,
                isPutting: false,
                putError: action.payload,
            };

        case DELETE_SNIPPET_BEGIN:
            return {
                ...state,
                isDeleting: true,
                deleteError: null,
            };
        case DELETE_SNIPPET_SUCCESS:
            let d_snippetId = action.payload.snippetId;
            let d_categories = action.payload.categories;
            let d_subcategories = action.payload.subcategories;

            let d_newRawSnippets = [];
            state.rawData.forEach((snippetGroup) => {
                let deleted = false;
                let newSnippets = snippetGroup.snippets.filter((snippet) => {
                    if (snippet._id === d_snippetId) {
                        deleted = true;
                        return false;
                    }
                    return true;
                });
                if (newSnippets.length) {
                    d_newRawSnippets.push({ ...snippetGroup, snippets: newSnippets, count: deleted ? snippetGroup.count - 1 : snippetGroup.count });
                }
            });

            const d_newSnippets = convertRawToStructured({ snippets: d_newRawSnippets, categories: d_categories, subcategories: d_subcategories });

            return {
                ...state,
                isDeleting: false,
                data: d_newSnippets,
                rawData: d_newRawSnippets,
            };
        case DELETE_SNIPPET_FAILURE:
            return {
                ...state,
                isDeleting: false,
                deleteError: action.payload,
            };
        default:
            return state;
    }
}

const convertRawToStructured = ({ snippets, categories, subcategories }) => {
    let newSnippets = {};
    // initialise the categories
    snippets.forEach((snippetGroup) => {
        const { category: categoryId } = snippetGroup.group;

        if (!newSnippets[categoryId]) {
            let category = categories.find((category) => {
                return category._id === categoryId;
            });

            if (category) {
                newSnippets[categoryId] = { ...category, subcategories: [], snippets: [] };
            }
        }
    });

    // add snippets to categories and subcategories
    snippets.forEach((snippetGroup) => {
        try {
            const { category: categoryId, subcategory: subcategoryId } = snippetGroup.group;
            if (!subcategoryId) {
                // no subcategory, so add directly to category
                newSnippets[categoryId].snippets = snippetGroup.snippets;
                newSnippets[categoryId].count = snippetGroup.count;
            } else {
                let subcategory = subcategories.find((subcategory) => {
                    return subcategory._id === subcategoryId;
                });

                if (subcategory) {
                    newSnippets[categoryId].subcategories.push({ ...subcategory, count: snippetGroup.count, snippets: snippetGroup.snippets });
                }
            }
        } catch {}
    });

    let newSnippetsArray = Object.values(newSnippets);
    newSnippetsArray = sortLexical(newSnippetsArray, "name", false, true);
    newSnippetsArray.forEach((snippetCategory) => {
        snippetCategory.subcategories = sortLexical(snippetCategory.subcategories, "name", false, true);
    });

    return newSnippetsArray;
};

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Main from "containers/Main";
import Login from "views/Login";
import Register from "views/Register";
import SnippetContextMenu from "components/SnippetContextMenu";

const CloseButton = ({ closeToast }) => <i className="fal fa-times" onClick={closeToast} />;

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route path="/" component={Main} />
            </Switch>
            <ToastContainer position="bottom-right" hideProgressBar={true} closeButton={<CloseButton />} />
            <SnippetContextMenu />
        </Router>
    );
};

export default App;

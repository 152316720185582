import { setOptions } from "views/Snippets/Editors/editorOptions";
import { setSidebar } from "views/Snippets/Sidebar/sidebarOptions";
import {
    RESET_SNIPPET,
    FETCH_SNIPPET_BEGIN,
    FETCH_SNIPPET_SUCCESS,
    FETCH_SNIPPET_FAILURE,
    SET_SNIPPET,
    SET_SNIPPET_ATTRIBUTE,
    SET_OPTIONS,
    SET_OPTION,
    SET_SIDEBAR,
    SET_SIDEBAR_ATTRIBUTE,
    DEFAULT_OPTIONS,
    DEFAULT_SIDEBAR,
    SET_SAVE_MODAL,
    SET_NEW_MODAL,
    SET_PRIMARY_EDITOR_REF,
    SET_SCRATCHPAD_EDITOR_REF,
} from "./snippet-page.actions";

const DEFAULT_SNIPPET = {
    _id: "",
    value: "",
    name: "",
    notes: "",
    category: "",
    subcategory: "",
    labels: [],
};

const initialState = {
    snippet: DEFAULT_SNIPPET,
    rawSnippet: DEFAULT_SNIPPET,
    primaryEditorRef: null,
    scratchpadEditorRef: null,
    isFetching: false,
    fetchError: null,

    options: DEFAULT_OPTIONS,
    sidebar: DEFAULT_SIDEBAR,
    showSaveModal: false,
    showNewModal: false,
};

export default function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_SNIPPET:
            return {
                ...state,
                snippet: DEFAULT_SNIPPET,
                rawSnippet: DEFAULT_SNIPPET,
            };

        case FETCH_SNIPPET_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchError: null,
            };
        case FETCH_SNIPPET_SUCCESS:
            return {
                ...state,
                isFetching: false,
                snippet: action.payload,
                rawSnippet: action.payload,
            };
        case FETCH_SNIPPET_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload,
                snippet: DEFAULT_SNIPPET,
                rawSnippet: DEFAULT_SNIPPET,
            };
        case SET_SNIPPET:
            return {
                ...state,
                snippet: action.payload,
                rawSnippet: action.payload,
            };
        case SET_SNIPPET_ATTRIBUTE:
            return {
                ...state,
                snippet: { ...state.snippet, [action.payload.name]: action.payload.value },
            };
        case SET_OPTIONS:
            setOptions(action.payload);
            return {
                ...state,
                options: action.payload,
            };
        case SET_OPTION:
            setOptions({ ...state.options, [action.payload.name]: action.payload.value });
            return {
                ...state,
                options: { ...state.options, [action.payload.name]: action.payload.value },
            };
        case SET_SIDEBAR:
            setSidebar(action.payload);
            return {
                ...state,
                sidebar: action.payload,
            };
        case SET_SIDEBAR_ATTRIBUTE:
            setSidebar({ ...state.sidebar, [action.payload.name]: action.payload.value });
            return {
                ...state,
                sidebar: { ...state.sidebar, [action.payload.name]: action.payload.value },
            };
        case SET_SAVE_MODAL:
            return {
                ...state,
                showSaveModal: action.payload,
            };
        case SET_NEW_MODAL:
            return {
                ...state,
                showNewModal: action.payload,
            };
        case SET_PRIMARY_EDITOR_REF:
            return {
                ...state,
                primaryEditorRef: action.payload,
            };
        case SET_SCRATCHPAD_EDITOR_REF:
            return {
                ...state,
                scratchpadEditorRef: action.payload,
            };
        default:
            return state;
    }
}

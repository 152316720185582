import { addSnippet, updateSnippet, removeSnippet, getSnippets, getMoreSnippets, copySnippet } from "utils/api/snippets";

export const RESET_SNIPPETS = "RESET_SNIPPETS";

export const resetSnippets = () => ({
    type: RESET_SNIPPETS,
});

export const FETCH_SNIPPETS_BEGIN = "FETCH_SNIPPETS_BEGIN";
export const FETCH_SNIPPETS_SUCCESS = "FETCH_SNIPPETS_SUCCESS";
export const FETCH_SNIPPETS_FAILURE = "FETCH_SNIPPETS_FAILURE";

export const fetchSnippetsBegin = () => ({
    type: FETCH_SNIPPETS_BEGIN,
});

export const fetchSnippetsSuccess = (snippets) => ({
    type: FETCH_SNIPPETS_SUCCESS,
    payload: snippets,
});

export const fetchSnippetsFailure = (error) => ({
    type: FETCH_SNIPPETS_FAILURE,
    payload: error,
});

export const fetchSnippets = ({ categories, subcategories }) => {
    return async (dispatch) => {
        dispatch(fetchSnippetsBegin());
        try {
            const res = await getSnippets();
            dispatch(fetchSnippetsSuccess({ categories, subcategories, snippets: res.data }));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(fetchSnippetsFailure(err));
            return false;
        }
    };
};

export const FETCH_MORE_SNIPPETS_BEGIN = "FETCH_MORE_SNIPPETS_BEGIN";
export const FETCH_MORE_SNIPPETS_SUCCESS = "FETCH_MORE_SNIPPETS_SUCCESS";
export const FETCH_MORE_SNIPPETS_FAILURE = "FETCH_MORE_SNIPPETS_FAILURE";

export const fetchMoreSnippetsBegin = () => ({
    type: FETCH_MORE_SNIPPETS_BEGIN,
});

export const fetchMoreSnippetsSuccess = (snippets) => ({
    type: FETCH_MORE_SNIPPETS_SUCCESS,
    payload: snippets,
});

export const fetchMoreSnippetsFailure = (error) => ({
    type: FETCH_MORE_SNIPPETS_FAILURE,
    payload: error,
});

export const fetchMoreSnippets = ({ category, subcategory, offset }) => {
    return async (dispatch) => {
        dispatch(fetchMoreSnippetsBegin());
        try {
            const res = await getMoreSnippets({ category, subcategory, offset });
            dispatch(fetchMoreSnippetsSuccess({ category, subcategory, snippets: res.data }));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(fetchMoreSnippetsFailure(err));
            return false;
        }
    };
};

export const POST_SNIPPET_BEGIN = "POST_SNIPPET_BEGIN";
export const POST_SNIPPET_SUCCESS = "POST_SNIPPET_SUCCESS";
export const POST_SNIPPET_FAILURE = "POST_SNIPPET_FAILURE";

export const postSnippetBegin = () => ({
    type: POST_SNIPPET_BEGIN,
});

export const postSnippetSuccess = (snippet) => ({
    type: POST_SNIPPET_SUCCESS,
    payload: snippet,
});

export const postSnippetFailure = (error) => ({
    type: POST_SNIPPET_FAILURE,
    payload: error,
});

export const postSnippet = ({ item, categories, subcategories }) => {
    return async (dispatch) => {
        dispatch(postSnippetBegin());
        try {
            const res = await addSnippet({ item });
            dispatch(postSnippetSuccess({ snippet: res.data, categories, subcategories }));
            return {
                success: true,
                snippet: res.data,
            };
        } catch (err) {
            dispatch(postSnippetFailure(err));
            return {
                success: false,
                snippet: null,
            };
        }
    };
};

export const cloneSnippet = ({ id, categories, subcategories }) => {
    return async (dispatch) => {
        dispatch(postSnippetBegin());
        try {
            const res = await copySnippet({ id });
            dispatch(postSnippetSuccess({ snippet: res.data, categories, subcategories }));
            return {
                success: true,
                snippet: res.data,
            };
        } catch (err) {
            dispatch(postSnippetFailure(err));
            return {
                success: false,
                snippet: null,
            };
        }
    };
};

export const PUT_SNIPPET_BEGIN = "PUT_SNIPPET_BEGIN";
export const PUT_SNIPPET_SUCCESS = "PUT_SNIPPET_SUCCESS";
export const PUT_SNIPPET_FAILURE = "PUT_SNIPPET_FAILURE";

export const putSnippetBegin = () => ({
    type: PUT_SNIPPET_BEGIN,
});

export const putSnippetSuccess = (snippet) => ({
    type: PUT_SNIPPET_SUCCESS,
    payload: snippet,
});

export const putSnippetFailure = (error) => ({
    type: PUT_SNIPPET_FAILURE,
    payload: error,
});

export const putSnippet = ({ id, item, categories, subcategories }) => {
    return async (dispatch) => {
        dispatch(putSnippetBegin());
        try {
            const res = await updateSnippet({ id, item });
            dispatch(putSnippetSuccess({ snippet: res.data, categories, subcategories }));
            return {
                success: true,
                snippet: res.data,
            };
        } catch (err) {
            console.error(err);
            dispatch(putSnippetFailure(err));
            return {
                success: false,
                snippet: null,
            };
        }
    };
};

export const DELETE_SNIPPET_BEGIN = "DELETE_SNIPPET_BEGIN";
export const DELETE_SNIPPET_SUCCESS = "DELETE_SNIPPET_SUCCESS";
export const DELETE_SNIPPET_FAILURE = "DELETE_SNIPPET_FAILURE";

export const deleteSnippetBegin = () => ({
    type: DELETE_SNIPPET_BEGIN,
});

export const deleteSnippetSuccess = (payload) => ({
    type: DELETE_SNIPPET_SUCCESS,
    payload,
});

export const deleteSnippetFailure = (error) => ({
    type: DELETE_SNIPPET_FAILURE,
    payload: error,
});

export const deleteSnippet = ({ id, categories, subcategories }) => {
    return async (dispatch) => {
        dispatch(deleteSnippetBegin());
        try {
            const res = await removeSnippet({ id });
            dispatch(deleteSnippetSuccess({ snippetId: res.data._id, categories, subcategories }));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(deleteSnippetFailure(err));
            return false;
        }
    };
};

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";

// import { getSidebar } from "./Sidebar/sidebarOptions";
import history from "utils/history";
import settings from "utils/settings";
import useReduxDataFetching from "utils/hooks/useReduxDataFetching";
import { isEqualEntity } from "utils/util";
import { fetchCategories } from "reduxStore/categories/categories.actions";
import { fetchSubcategories } from "reduxStore/subcategories/subcategories.actions";
import { fetchLabels } from "reduxStore/labels/labels.actions";
import { setSidebarAttribute, onSave, setSaveModal, onNew, setNewModal, resetSnippet } from "reduxStore/snippet-page/snippet-page.actions";
import { fetchSnippet, setOption } from "reduxStore/snippet-page/snippet-page.actions";

const useSnippets = () => {
    const { fetchError: fetchCategoriesError } = useReduxDataFetching(fetchCategories, "categories");
    const { fetchError: fetchSubcategoriesError } = useReduxDataFetching(fetchSubcategories, "subcategories");
    const { fetchError: fetchLabelsError } = useReduxDataFetching(fetchLabels, "labels");
    const { sidebar, snippet, rawSnippet, options, showSaveModal, primaryEditorRef, scratchpadEditorRef } = useSelector((state) => state.snippetPage);
    const { data: categories } = useSelector((state) => state.categories);
    const { data: subcategories } = useSelector((state) => state.subcategories);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetSnippet());
        };
    }, [dispatch]);

    useEffect(() => {
        if (fetchCategoriesError) {
            toast.error("an unknown error has occurred when getting categories.");
        }
        if (fetchSubcategoriesError) {
            toast.error("an unknown error has occurred when getting subcategories.");
        }
        if (fetchLabelsError) {
            toast.error("an unknown error has occurred when getting labels.");
        }
    }, [fetchCategoriesError, fetchSubcategoriesError, fetchLabelsError]);

    const forceResize = () => {
        primaryEditorRef?.current && primaryEditorRef.current.forceResize();
        scratchpadEditorRef?.current && scratchpadEditorRef.current.forceResize();
    };

    const [debounceSetSidebarSize] = useDebouncedCallback((size) => {
        dispatch(setSidebarAttribute({ name: "size", value: size }));
        forceResize();
    }, settings.debounce.typing);

    const onSidebarSizeChange = (size) => {
        debounceSetSidebarSize(size);
    };

    const onKeyDown = async (e) => {
        if (e.ctrlKey && e.shiftKey && e.which === 83) {
            // open save modal
            e.preventDefault();
            dispatch(setSaveModal(true));
        } else if (e.ctrlKey && e.which === 83) {
            // save
            e.preventDefault();
            if (isEqualEntity({ ...snippet, mode: options.mode }, { ...rawSnippet, mode: rawSnippet.mode || options.mode })) {
                return;
            }
            const res = await dispatch(onSave({ snippet, options, categories, subcategories, showSaveModal }));
            if (res.success) {
                dispatch(setSaveModal(false));
                toast(`snippet "${res.snippet.name.toLowerCase()}" saved.`, { type: res.type === "add" ? toast.TYPE.SUCCESS : toast.TYPE.DEFAULT });
            }
        } else if (e.ctrlKey && e.shiftKey && e.which === 78) {
            // new
            e.preventDefault();
            if (isEqualEntity({ ...snippet, mode: options.mode }, { ...rawSnippet, mode: rawSnippet.mode || options.mode })) {
                dispatch(onNew());
                primaryEditorRef?.current && primaryEditorRef.current.setValue("");
            } else {
                dispatch(setNewModal(true));
            }
        }
    };

    useEffect(() => {
        if (primaryEditorRef) {
            let ss = window.location.search;
            let snippetId = qs.parse(ss[0] === "?" ? ss.substr(1) : ss)?.id || "";
            if (snippetId) {
                loadSnippet({ id: snippetId });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryEditorRef]);

    const loadSnippet = async ({ id }) => {
        const res = await dispatch(fetchSnippet({ id }));
        if (res.success) {
            primaryEditorRef?.current && primaryEditorRef.current.setValue(res?.snippet?.value || "");
            dispatch(setOption({ name: "mode", value: res.snippet?.mode }));
            history.push({
                pathname: "/snippets",
                search: `?id=${res.snippet._id}`,
            });
        }
    };

    return {
        sidebarSize: sidebar.size,
        onSidebarSizeChange,
        onKeyDown,
        loadSnippet,
    };
};

export default useSnippets;

import React from "react";

import useSidebar from "./useSidebar";
import Category from "./Category";

const Sidebar = ({ loadSnippet }) => {
    const { sidebar, snippetCategories, handleNavigation, activeSnippet } = useSidebar();

    return (
        <div className="sidebar" onKeyDown={handleNavigation} style={{ display: sidebar?.size ? "block" : "none" }}>
            {snippetCategories?.length === 0 ? (
                <div className="text-muted text-center mt-2">
                    {"<"}no snippets{">"}
                </div>
            ) : (
                snippetCategories.map((snippetCategory) => (
                    <Category key={snippetCategory._id} category={snippetCategory} loadSnippet={loadSnippet} activeSnippet={activeSnippet} />
                ))
            )}
        </div>
    );
};

export default Sidebar;

import { TOGGLE_CONTEXT_MENU_ON, TOGGLE_CONTEXT_MENU_OFF } from "./context-menu.actions";

const menus = ["sidebarSnippet"];
const initialState = menus.reduce((acc, val) => ({ ...acc, [val]: { show: false, data: {} } }), {});

export default function contextMenuReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_CONTEXT_MENU_ON:
            return {
                ...state,
                [action.payload.key]: {
                    position: action.payload.position,
                    data: action.payload.data,
                    show: true,
                },
            };
        case TOGGLE_CONTEXT_MENU_OFF:
            return {
                ...state,
                [action.payload]: { data: {}, show: false },
            };
        default:
            return state;
    }
}

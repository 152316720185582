import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import useReduxDataFetching from "utils/hooks/useReduxDataFetching";
import useReduxDataPosting from "utils/hooks/useReduxDataPosting";
import { fetchCategories, postCategory } from "reduxStore/categories/categories.actions";

const useCategories = () => {
    const { data: categories, fetchError } = useReduxDataFetching(fetchCategories, "categories");
    const { postData, postError } = useReduxDataPosting(postCategory, "categories");
    const { putError, deleteError } = useSelector((state) => state.categories);

    useEffect(() => {
        if (fetchError) {
            toast.error("an unknown error has occurred when getting categories.");
        }
        if (postError) {
            if (postError?.response?.data?.message === "duplicate key error") {
                toast.error("error: a category with that name already exists.");
            } else {
                toast.error("an unknown error has occurred when adding a category.");
            }
        }
        if (putError) {
            if (putError?.response?.data?.message === "duplicate key error") {
                toast.error("error: a category with that name already exists.");
            } else {
                toast.error("an unknown error has occurred when updating a category.");
            }
        }
        if (deleteError) {
            toast.error("an unknown error has occurred when deleting a category.");
        }
    }, [fetchError, postError, putError, deleteError]);

    const [category, setCategory] = useState({});

    const onChange = (evt) => {
        const { value, name } = evt.target;
        setCategory({ ...category, [name]: value });
    };

    const addCategory = async () => {
        const success = await postData(category);
        if (success) {
            toast.success(`category "${category.name}" added.`.toLowerCase());
            setCategory({});
        }
    };

    const onKeyPress = (e) => {
        if (e.which === 13) {
            onAddClicked();
        }
    };

    const onAddClicked = async () => {
        if (!category.name) return;
        addCategory();
    };

    return {
        // fns
        onChange,
        onAddClicked,
        onKeyPress,

        // data
        categories,
        category,
    };
};

export default useCategories;

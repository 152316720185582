import React from "react";

const FontSize = ({ value, onChange, idPrefix, ...rest }) => {
    const onDecrementFontSize = () => {
        if (value <= 8) return;
        onChange({ name: "fontSize", value: value - 1 });
    };
    const onIncrementFontSize = () => {
        if (value >= 36) return;
        onChange({ name: "fontSize", value: value + 1 });
    };

    return (
        <div className="editor-header-option d-flex align-items-center" {...rest}>
            <div className="font-size-btn" onClick={onDecrementFontSize}>
                <i className="fal fa-minus" />
            </div>
            <div style={{ display: "flex", alignItems: "center" }} className="noselect">
                {value}
            </div>
            <div className="font-size-btn" onClick={onIncrementFontSize}>
                <i className="fal fa-plus" />
            </div>
        </div>
    );
};

export default FontSize;

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

import settings from "utils/settings";

import { Button } from "components/ReactBootstrapWrappers";

const Format = ({ value, onChange, idPrefix, ...rest }) => {
    const onFormat = () => {
        onChange(value);
        try {
            const beautifiedJSON = JSON.stringify(JSON.parse(value), null, 4);
            onChange(beautifiedJSON);
        } catch (err) {
            let errMsg = "unknown error occurred when beautifying json.";
            if (err.message) {
                errMsg = err.message.replace(/^JSON.parse: /, "");
            }
            toast.error(`error: ${errMsg}`);
        }
    };

    return (
        <div className="editor-header-option" {...rest}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`${idPrefix}-tooltip-json-format`}>beautify json</Tooltip>}
                delay={{ show: settings.tooltipTimeout, hide: 0 }}
            >
                <Button
                    variant="outline-dark"
                    onClick={onFormat}
                    style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                >
                    <i className="fal fa-indent" />
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default Format;

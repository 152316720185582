import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";

import { isEqual } from "utils/lodashReplacements";
import { clearMetadata } from "utils/util";
import settings from "utils/settings";

const useHeader = () => {
    const { snippet, rawSnippet, options } = useSelector((state) => state.snippetPage);
    const { data: categories } = useSelector((state) => state.categories);
    const { data: subcategories } = useSelector((state) => state.subcategories);

    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");

    useEffect(() => {
        let name = "";
        let categoryName = "";
        let subcategoryName = "";
        if (snippet.category) {
            let category = categories.find((category) => {
                return category._id === snippet.category;
            });
            if (category) {
                categoryName = category.name;
                categoryName = categoryName.replace(/\s+/g, "-");
            }
        }
        if (snippet.subcategory) {
            let subcategory = subcategories.find((subcategory) => {
                return subcategory._id === snippet.subcategory;
            });
            if (subcategory) {
                subcategoryName = subcategory.name;
                subcategoryName = subcategoryName.replace(/\s+/g, "-");
            }
        }
        if (snippet.name) {
            // generate the title text
            name = snippet.name.trim();
            name = name.replace(/\s+/g, "-");
            name = name.replace(/[^a-z0-9_\-[\]]/gim, "_");

            // generate the "file extension"
            let modeText = "";
            if (snippet.mode === "javascript") {
                modeText = ".js";
            }
            if (snippet.mode === "json") {
                modeText = ".json";
            }
            if (snippet.mode === "text") {
                modeText = ".txt";
            }
            if (snippet.mode === "markdown") {
                modeText = ".md";
            }
            if (snippet.mode === "scss") {
                modeText = ".scss";
            }
            if (snippet.mode === "sql") {
                modeText = ".sql";
            }
            if (name) {
                name = `${name}${modeText}`;
            }
        }
        setName(name);
        setCategory(categoryName);
        setSubcategory(subcategoryName);
    }, [snippet.name, snippet.mode, snippet.category, snippet.subcategory, categories, subcategories]);

    const onCopy = async () => {
        try {
            await navigator.clipboard.writeText(snippet.name);
            toast("copied snippet name to clipboard");
        } catch (err) {
            toast.error("failed to copy snippet name to clipboard");
        }
    };

    const [hasChanges, setHasChanges] = useState(false);
    const [debounceIsEqual] = useDebouncedCallback((s1, s2) => {
        if (!isEqual(clearMetadata(s1), clearMetadata(s2))) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, settings.debounce.typing);

    useEffect(() => {
        debounceIsEqual({ ...snippet, mode: options.mode }, { ...rawSnippet, mode: rawSnippet.mode || options.mode });
    }, [snippet, rawSnippet, options, debounceIsEqual]);

    return {
        name,
        category,
        subcategory,
        hasChanges,
        onCopy,
    };
};

export default useHeader;

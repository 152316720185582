import { getSquads, addSquad, updateSquad, removeSquad, leaveSquad as leaveSquadApi } from "utils/api/squads";
import apiClient from "utils/api/axios";
import { resetCategories } from "reduxStore/categories/categories.actions";
import { resetSubcategories } from "reduxStore/subcategories/subcategories.actions";
import { resetLabels } from "reduxStore/labels/labels.actions";
import { resetSnippets } from "reduxStore/snippets/snippets.actions";
import history from "utils/history";
import { resetSnippet } from "reduxStore/snippet-page/snippet-page.actions";

export const SET_SQUAD = "SET_SQUAD";

export const setSquad = (squad) => ({
    type: SET_SQUAD,
    payload: squad,
});

export const changeSquad = (squad) => {
    return async (dispatch) => {
        apiClient.defaults.headers.common["Squad"] = squad?._id || "";
        dispatch(resetCategories());
        dispatch(resetSubcategories());
        dispatch(resetLabels());
        dispatch(resetSnippets());
        dispatch(resetSnippet());
        dispatch(setSquad(squad));
        if (window.location.pathname === "/snippets") {
            history.push({ pathname: window.location.pathname, search: "" });
        }
    };
};

export const FETCH_SQUADS_BEGIN = "FETCH_SQUADS_BEGIN";
export const FETCH_SQUADS_SUCCESS = "FETCH_SQUADS_SUCCESS";
export const FETCH_SQUADS_FAILURE = "FETCH_SQUADS_FAILURE";

export const fetchSquadsBegin = () => ({
    type: FETCH_SQUADS_BEGIN,
});

export const fetchSquadsSuccess = (squads, squadId) => ({
    type: FETCH_SQUADS_SUCCESS,
    payload: { squads, squadId },
});

export const fetchSquadsFailure = (error) => ({
    type: FETCH_SQUADS_FAILURE,
    payload: error,
});

export const fetchSquads = (squadId) => {
    return async (dispatch) => {
        dispatch(fetchSquadsBegin());
        try {
            const res = await getSquads();
            dispatch(fetchSquadsSuccess(res.data, squadId));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(fetchSquadsFailure(err));
            return false;
        }
    };
};

export const POST_SQUAD_BEGIN = "POST_SQUAD_BEGIN";
export const POST_SQUAD_SUCCESS = "POST_SQUAD_SUCCESS";
export const POST_SQUAD_FAILURE = "POST_SQUAD_FAILURE";

export const postSquadBegin = () => ({
    type: POST_SQUAD_BEGIN,
});

export const postSquadSuccess = (squad) => ({
    type: POST_SQUAD_SUCCESS,
    payload: squad,
});

export const postSquadFailure = (error) => ({
    type: POST_SQUAD_FAILURE,
    payload: error,
});

export const postSquad = ({ item }) => {
    return async (dispatch) => {
        dispatch(postSquadBegin());
        try {
            const res = await addSquad({ item });
            dispatch(postSquadSuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(postSquadFailure(err));
            return false;
        }
    };
};

export const PUT_SQUAD_BEGIN = "PUT_SQUAD_BEGIN";
export const PUT_SQUAD_SUCCESS = "PUT_SQUAD_SUCCESS";
export const PUT_SQUAD_FAILURE = "PUT_SQUAD_FAILURE";

export const putSquadBegin = () => ({
    type: PUT_SQUAD_BEGIN,
});

export const putSquadSuccess = (squad) => ({
    type: PUT_SQUAD_SUCCESS,
    payload: squad,
});

export const putSquadFailure = (error) => ({
    type: PUT_SQUAD_FAILURE,
    payload: error,
});

export const putSquad = ({ id, item }) => {
    return async (dispatch) => {
        dispatch(putSquadBegin());
        try {
            const res = await updateSquad({ id, item });
            dispatch(putSquadSuccess(res.data));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(putSquadFailure(err));
            return false;
        }
    };
};

export const DELETE_SQUAD_BEGIN = "DELETE_SQUAD_BEGIN";
export const DELETE_SQUAD_SUCCESS = "DELETE_SQUAD_SUCCESS";
export const DELETE_SQUAD_FAILURE = "DELETE_SQUAD_FAILURE";

export const deleteSquadBegin = () => ({
    type: DELETE_SQUAD_BEGIN,
});

export const deleteSquadSuccess = (squadId) => ({
    type: DELETE_SQUAD_SUCCESS,
    payload: squadId,
});

export const deleteSquadFailure = (error) => ({
    type: DELETE_SQUAD_FAILURE,
    payload: error,
});

export const deleteSquad = ({ id }) => {
    return async (dispatch) => {
        dispatch(deleteSquadBegin());
        try {
            const res = await removeSquad({ id });
            dispatch(deleteSquadSuccess(res.data._id));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(deleteSquadFailure(err));
            return false;
        }
    };
};

export const LEAVE_SQUAD_BEGIN = "LEAVE_SQUAD_BEGIN";
export const LEAVE_SQUAD_SUCCESS = "LEAVE_SQUAD_SUCCESS";
export const LEAVE_SQUAD_FAILURE = "LEAVE_SQUAD_FAILURE";

export const leaveSquadBegin = () => ({
    type: LEAVE_SQUAD_BEGIN,
});

export const leaveSquadSuccess = (squadId) => ({
    type: LEAVE_SQUAD_SUCCESS,
    payload: squadId,
});

export const leaveSquadFailure = (error) => ({
    type: LEAVE_SQUAD_FAILURE,
    payload: error,
});

export const leaveSquad = ({ id }) => {
    return async (dispatch) => {
        dispatch(leaveSquadBegin());
        try {
            const res = await leaveSquadApi({ id });
            dispatch(leaveSquadSuccess(res.data._id));
            return true;
        } catch (err) {
            console.error(err);
            dispatch(leaveSquadFailure(err));
            return false;
        }
    };
};

import { setSquadLocal } from "utils/hooks/useAuth";
import { sortLexical } from "utils/util";
import apiClient from "utils/api/axios";

import {
    SET_SQUAD,
    FETCH_SQUADS_BEGIN,
    FETCH_SQUADS_SUCCESS,
    FETCH_SQUADS_FAILURE,
    POST_SQUAD_BEGIN,
    POST_SQUAD_SUCCESS,
    POST_SQUAD_FAILURE,
    PUT_SQUAD_BEGIN,
    PUT_SQUAD_SUCCESS,
    PUT_SQUAD_FAILURE,
    DELETE_SQUAD_BEGIN,
    DELETE_SQUAD_SUCCESS,
    DELETE_SQUAD_FAILURE,
    LEAVE_SQUAD_BEGIN,
    LEAVE_SQUAD_SUCCESS,
    LEAVE_SQUAD_FAILURE,
} from "./squads.actions";

const initialState = {
    data: [],
    squad: null,

    isFetching: true,
    fetchError: null,

    isPosting: false,
    postError: null,

    isPutting: false,
    putError: null,

    isDeleting: false,
    deleteError: null,

    isLeaving: false,
    leaveError: null,
};

export default function squadsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SQUAD:
            setSquadLocal(action.payload?._id || "");
            return {
                ...state,
                squad: action.payload,
            };
        case FETCH_SQUADS_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchError: null,
            };
        case FETCH_SQUADS_SUCCESS:
            let selectedSquad = null;
            if (action.payload.squadId) {
                selectedSquad = action.payload.squads.find((squad) => {
                    return squad._id === action.payload.squadId;
                });

                if (!selectedSquad) {
                    // set squad to null in local storage
                    setSquadLocal(null);
                    apiClient.defaults.headers.common["Squad"] = "";
                } else {
                    apiClient.defaults.headers.common["Squad"] = selectedSquad._id;
                }
            }

            return {
                ...state,
                isFetching: false,
                data: sortLexical(action.payload.squads, "name", false, true),
                squad: selectedSquad,
            };
        case FETCH_SQUADS_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload,
                data: [],
            };

        case POST_SQUAD_BEGIN:
            return {
                ...state,
                isPosting: true,
                postError: null,
            };
        case POST_SQUAD_SUCCESS:
            return {
                ...state,
                isPosting: false,
                data: sortLexical([...state.data, action.payload], "name", false, true),
            };
        case POST_SQUAD_FAILURE:
            return {
                ...state,
                isPosting: false,
                postError: action.payload,
            };

        case PUT_SQUAD_BEGIN:
            return {
                ...state,
                isPutting: true,
                putError: null,
            };
        case PUT_SQUAD_SUCCESS:
            let u_squads = [...state.data];
            u_squads.forEach((squad, i) => {
                if (squad._id === action.payload._id) {
                    u_squads[i] = action.payload;
                    return false;
                }
            });
            // update the selected squad if necessary
            let u_squad = state.squad;
            if (state.squad._id === action.payload._id) {
                u_squad = action.payload;
            }
            return {
                ...state,
                isPutting: false,
                data: sortLexical(u_squads, "name", false, true),
                squad: u_squad,
            };
        case PUT_SQUAD_FAILURE:
            return {
                ...state,
                isPutting: false,
                putError: action.payload,
            };

        case DELETE_SQUAD_BEGIN:
            return {
                ...state,
                isDeleting: true,
                deleteError: null,
            };
        case DELETE_SQUAD_SUCCESS:
            let d_squads = [...state.data].filter((squad) => {
                return squad._id !== action.payload;
            });
            // Set squad to null if deleted squad was selected squad
            if (action.payload === state.squad?._id) {
                setSquadLocal(null);
                apiClient.defaults.headers.common["Squad"] = "";
                return {
                    ...state,
                    isDeleting: false,
                    data: sortLexical(d_squads, "name", false, true),
                    squad: null,
                };
            }
            return {
                ...state,
                isDeleting: false,
                data: sortLexical(d_squads, "name", false, true),
            };
        case DELETE_SQUAD_FAILURE:
            return {
                ...state,
                isDeleting: false,
                deleteError: action.payload,
            };

        case LEAVE_SQUAD_BEGIN:
            return {
                ...state,
                isLeaving: true,
                leaveError: null,
            };
        case LEAVE_SQUAD_SUCCESS:
            let l_squads = [...state.data].filter((squad) => {
                return squad._id !== action.payload;
            });
            // Set squad to null if squad left was selected squad
            if (action.payload === state.squad?._id) {
                setSquadLocal(null);
                apiClient.defaults.headers.common["Squad"] = "";
                return {
                    ...state,
                    isLeaving: false,
                    data: sortLexical(l_squads, "name", false, true),
                    squad: null,
                };
            }
            return {
                ...state,
                isLeaving: false,
                data: sortLexical(l_squads, "name", false, true),
            };
        case LEAVE_SQUAD_FAILURE:
            return {
                ...state,
                isLeaving: false,
                leaveError: action.payload,
            };
        default:
            return state;
    }
}

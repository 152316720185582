const ROUTES = {
    "/snippets": {
        name: "snippet editor",
        icon: "fal fa-code",
    },
    "/manage": {
        name: "manage",
        icon: "fal fa-lock-alt",
    },
    "/squads": {
        name: "squads",
        icon: "fal fa-user-friends",
    },
    "/settings": {
        name: "settings",
        icon: "fal fa-cog",
    },
};

export default ROUTES;

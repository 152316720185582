import React from "react";

import Editor from "components/Editor";
import PrimaryEditorHeader from "./PrimaryEditorHeader";
import usePrimaryEditor from "./usePrimaryEditor";

const PrimaryEditor = () => {
    const { options, onSnippetValueChange, innerRef } = usePrimaryEditor();
    return (
        <>
            <PrimaryEditorHeader />
            <Editor className="primary-editor" onChange={onSnippetValueChange} options={options} ref={innerRef} />
        </>
    );
};

export default PrimaryEditor;

import { useDispatch, useSelector } from "react-redux";

const useReduxDataPosting = (dataSource, key) => {
    const { data, postError, isPosting } = useSelector((state) => state[key]);
    const dispatch = useDispatch();

    const postData = async (item) => {
        return await dispatch(dataSource({ item }));
    };

    return {
        postData,
        postError,
        isPosting,
        data,
    };
};

export default useReduxDataPosting;

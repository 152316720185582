import React from "react";
import classNames from "classnames";

import useSnippetContextMenu from "./useSnippetContextMenu";

const SnippetContextMenu = () => {
    const { show, position, onOpenSnippet, onCopySnippet, onDeleteSnippet } = useSnippetContextMenu();

    return (
        <div
            className={classNames("sidebar-context-menu", { "sidebar-context-menu--active": show })}
            id="snippet-context-menu"
            style={{
                left: `${position?.x || 0}px`,
                top: `${position?.y || 0}px`,
            }}
        >
            <ul className="sidebar-context-menu-items">
                <li className="sidebar-context-menu-item" onClick={onOpenSnippet}>
                    <div className="mr-2 d-flex justify-content-center align-items-center" style={{ minWidth: "20px" }}>
                        <i className="fal fa-folder-open mr-2 text-primary" />
                    </div>
                    open
                </li>
                <li className="sidebar-context-menu-item" onClick={onCopySnippet}>
                    <div className="mr-2 d-flex justify-content-center align-items-center" style={{ minWidth: "20px" }}>
                        <i className="fal fa-copy mr-2 text-primary" />
                    </div>
                    make a copy
                </li>
                <li className="sidebar-context-menu-item" onClick={onDeleteSnippet}>
                    <div className="mr-2 d-flex justify-content-center align-items-center" style={{ minWidth: "20px" }}>
                        <i className="fal fa-trash mr-2 text-primary" />
                    </div>
                    delete
                </li>
            </ul>
        </div>
    );
};

export default React.memo(SnippetContextMenu);

import settings from "utils/settings";
import { isEqual } from "./lodashReplacements";

/**
 * Obtains a theme colour, falls back on defaults if no theme present
 *
 * @param key the key to obtain from theme
 * @param theme the theme itself
 * @param defaults the default colours object
 * @returns {string} hex value
 */
export function getThemeColour(key, theme, defaults = settings.theme) {
    const DEFAULT_P = defaults.primary;
    let c = null;
    switch (key) {
        // Primary cases
        case "primary":
        case "primary-dark-1":
        case "primary-dark-2":
        case "primary-light-1":
        case "primary-light-2":
            c = theme?.[key] || DEFAULT_P;
            if (c) return c;
            else return theme?.primary || DEFAULT_P;
        case "secondary":
            return "#c7c7c7";
        case "success":
            return "#bdea74";
        case "info":
            return "#67c2ef";
        case "warning":
            return "#ffc107";
        case "danger":
            return "#ff5454";
        case "dark":
            return "#4d4d4d";
        case "light":
            return "#c7c7c7";
        default:
            c = theme?.primary || DEFAULT_P;
            if (c) return c;
            return DEFAULT_P;
    }
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
}

/**
 * Sort the array, with optional key, reverse, and ignore case
 *
 * @param {array} array
 * @param {string} key
 * @param {boolean} reverse reverse lexical order?
 * @param {boolean} ignoreCase
 */
export function sortLexical(array, key, reverse, ignoreCase) {
    const returnArr = array.sort((x, y) => {
        let a = x;
        let b = y;

        if (key?.length) {
            a = ignoreCase ? x[key].toLowerCase() : x[key];
            b = ignoreCase ? y[key].toLowerCase() : y[key];
        }

        if (reverse) {
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
        }
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    });
    return returnArr;
}

export function clearMetadata(item) {
    return {
        ...item,
        __v: undefined,
        _id: undefined,
        tid: undefined,
        uid: undefined,
        createdAt: undefined,
        updatedAt: undefined,
    };
}

export const isEqualEntity = (s1, s2) => {
    return isEqual({ ...clearMetadata(s1), _id: s1._id }, { ...clearMetadata(s2), _id: s2._id });
};

export function clickInsideElement(e, className) {
    let el = e.srcElement || e.target;

    if (el.classList.contains(className)) {
        return el;
    } else {
        while ((el = el.parentNode)) {
            if (el.classList && el.classList.contains(className)) {
                return el;
            }
        }
    }

    return false;
}

export function getModeIcon(mode) {
    switch (mode) {
        case "javascript":
            return "fab fa-js";
        case "json":
            return "far fa-brackets-curly";
        case "markdown":
            return "fab fa-markdown";
        case "sql":
            return "fas fa-database";
        case "scss":
            return "fab fa-sass";
        case "text":
        default:
            return "fas fa-file";
    }
}

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { signIn, clearLoginError } from "reduxStore/auth/auth.actions";

const useLogin = () => {
    const [formData, setFormData] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const { loginError } = useSelector((state) => state.auth);
    const theme = useSelector((state) => state.theme);

    useEffect(() => {
        return () => {
            dispatch(clearLoginError());
        };
    }, [dispatch]);

    useEffect(() => {
        if (loginError) {
            if (loginError?.response?.data?.message) {
                setErrorMsg(`login error: ${loginError.response.data.message.toLowerCase()}`);
            } else {
                setErrorMsg("login error: an unknown error has occurred.");
            }
        }
    }, [loginError]);

    const onChange = (evt) => {
        const { value, name } = evt.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = async () => {
        if (!validate(formData)) {
            setErrorMsg("error: username and password fields are required.");
            return;
        }
        const success = await dispatch(signIn(formData));
        if (success) {
            history.push("/");
        }
    };

    const hideError = () => {
        setErrorMsg(null);
    };

    const onKeyPress = (e) => {
        if (e.which === 13) {
            onSubmit();
        }
    };

    const validate = (formData) => {
        if (!formData.username || !formData.password) return false;
        return true;
    };

    return {
        // fns
        onChange,
        onKeyPress,
        onSubmit,
        hideError,

        // data
        formData,
        errorMsg,
        theme,
    };
};

export default useLogin;

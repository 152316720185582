import React, { useState } from "react";
import Select, { components } from "react-select";
import { useDebouncedCallback } from "use-debounce";

import settings from "utils/settings";
import { findUsers } from "utils/api/users";
import useSelectStyles from "utils/hooks/useSelectStyles";

const IndicatorSeparator = () => null;
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fal fa-search" />
        </components.DropdownIndicator>
    );
};

const UserSearch = (props) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (option) => {
        props.onChange({
            username: option.label,
            _id: option.value,
        });
    };

    const queryUsers = async (searchString) => {
        if (!searchString || searchString.length < 4) {
            setOptions([]);
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        try {
            const res = await findUsers(searchString);
            setOptions(
                res.data?.map((user) => {
                    return { value: user._id, label: user.username };
                }) || []
            );
        } catch (err) {}
        setIsLoading(false);
    };

    const [debounceOnInputChange] = useDebouncedCallback((data) => {
        queryUsers(data);
    }, settings.debounce.typing);

    const getNoOptionsMessage = ({ inputValue }) => {
        if (!inputValue || inputValue.length < 4) {
            return "search by username or email";
        }
        return "no results";
    };

    const { styles } = useSelectStyles({});

    return (
        <Select
            value={null}
            isLoading={isLoading}
            options={options}
            onChange={onChange}
            placeholder="search users..."
            noOptionsMessage={getNoOptionsMessage}
            onInputChange={debounceOnInputChange}
            onMenuClose={() => setOptions([])}
            filterOption={() => true}
            autoFocus={true}
            styles={styles}
            components={{ IndicatorSeparator, DropdownIndicator }}
        />
    );
};

export default UserSearch;

import client from "./axios";

export const getSettings = () => {
    return client({
        method: "get",
        url: "/settings",
    });
};

export const updateSettings = ({ item }) => {
    return client({
        method: "post",
        url: "/settings",
        data: item,
    });
};

import React from "react";
import { Form } from "react-bootstrap";

import { Button } from "components/ReactBootstrapWrappers";
import CategoryEntry from "./CategoryEntry";
import useCategories from "./useCategories";

const Categories = () => {
    const { onChange, onAddClicked, onKeyPress, categories, category } = useCategories();

    return (
        <div className="page-section">
            <h4>categories</h4>
            <div className="page-group pt-2">
                <Form.Group>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            placeholder="name"
                            value={category.name || ""}
                            name="name"
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            data-lpignore="true"
                        />
                        <Button variant="outline-primary" className="flex-btn ml-2" disabled={!category.name} onClick={onAddClicked}>
                            <i className="fal fa-plus mr-2" />
                            add
                        </Button>
                    </div>
                </Form.Group>
                {categories.map((category) => (
                    <CategoryEntry key={category._id} category={category} />
                ))}
            </div>
        </div>
    );
};

export default Categories;

import React, { useRef } from "react";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { appName } from "utils/constants";
import settings from "utils/settings";

import NavigatorDropdown from "./NavigatorDropdown";
import SquadsDropdown from "./SquadsDropdown";
import UserDropdown from "./UserDropdown";
import useHeader from "./useHeader";

const Header = () => {
    const { category, subcategory, name, hasChanges, onCopy } = useHeader();

    const target = useRef(null);

    return (
        <div className="app-header">
            <div className="header-content">
                <div className="app-title">
                    <span className={classNames("title-secondary", { "text-muted": name })}>
                        {">"} {appName.toLowerCase()}
                        {name && category && `/${category}`}
                        {name && subcategory && `/${subcategory}`}
                        {name && "/"}
                    </span>
                    <span className="title-primary" onClick={onCopy}>
                        {name && `${name}`}
                    </span>
                    <span className="blinking-cursor">_</span>
                    <span style={{ visibility: hasChanges ? "visible" : "hidden", transition: "visibility 0.15s ease-in-out" }}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="has-changes-tooltip">unsaved changes</Tooltip>}
                            delay={{ show: settings.tooltipTimeout, hide: 0 }}
                        >
                            <i
                                className="fas fa-circle fa-xs ml-2"
                                style={{ opacity: hasChanges ? 1 : 0, transition: "opacity 0.15s ease-in-out" }}
                                innerRef={target}
                            />
                        </OverlayTrigger>
                    </span>
                </div>
                <div className="header-right">
                    <NavigatorDropdown />
                    <SquadsDropdown />
                    <UserDropdown />
                </div>
            </div>
        </div>
    );
};

export default Header;

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

import settings from "utils/settings";

import { Button } from "components/ReactBootstrapWrappers";

const Uglify = ({ value, onChange, idPrefix, ...rest }) => {
    const onUglify = () => {
        onChange(value);
        try {
            let uglifiedValue = value;

            // replace whitespace at the start of each line
            uglifiedValue = uglifiedValue.replace(/^\s*/gm, "");
            // replace whitespace anywhere else (e.g. "abc(\t)def")
            uglifiedValue = uglifiedValue.replace(/\s\s+/g, " ");
            // replace newlines with a space
            uglifiedValue = uglifiedValue.replace(/\n/g, " ");
            uglifiedValue = uglifiedValue.trim();

            onChange(uglifiedValue);
        } catch (err) {
            toast.error("error: unknown error occurred when uglifying javascript.");
        }
    };

    return (
        <div className="editor-header-option" {...rest}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`${idPrefix}-tooltip-javascript-uglify`}>uglify javascript</Tooltip>}
                delay={{ show: settings.tooltipTimeout, hide: 0 }}
            >
                <Button
                    variant="outline-dark"
                    onClick={onUglify}
                    style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                >
                    <i className="fal fa-align-center" />
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default Uglify;

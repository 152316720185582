import { useState, useEffect, useRef } from "react";
import settings from "utils/settings";

const useDoubleClickDelete = ({ callback }) => {
    const [tooltipDisabled, setTooltipDisabled] = useState(true);
    const [deleteClicks, setDeleteClicks] = useState(0);
    const deleteClicksRef = useRef(deleteClicks);
    deleteClicksRef.current = deleteClicks;

    const incrementDeleteClicks = () => {
        setDeleteClicks(deleteClicks + 1);
    };

    useEffect(() => {
        // reset delete clicks to 0 after X seconds
        const timeout = setTimeout(() => {
            if (deleteClicksRef.current >= 1) {
                setDeleteClicks(0);
            }
        }, settings.resetDeleteTimeout);
        if (deleteClicks > 1) {
            callback();
        }

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteClicks]);

    return {
        setTooltipDisabled,
        incrementDeleteClicks,
        deleteClicks,
        tooltipDisabled,
    };
};

export default useDoubleClickDelete;

import { sortLexical } from "utils/util";
import {
    RESET_CATEGORIES,
    FETCH_CATEGORIES_BEGIN,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    POST_CATEGORY_BEGIN,
    POST_CATEGORY_SUCCESS,
    POST_CATEGORY_FAILURE,
    PUT_CATEGORY_BEGIN,
    PUT_CATEGORY_SUCCESS,
    PUT_CATEGORY_FAILURE,
    DELETE_CATEGORY_BEGIN,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE,
} from "./categories.actions";

const initialState = {
    data: [],

    hasFetched: false,
    isFetching: false,
    fetchError: null,

    isPosting: false,
    postError: null,

    isPutting: false,
    putError: null,

    isDeleting: false,
    deleteError: null,
};

export default function categoriesReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_CATEGORIES:
            return initialState;
        case FETCH_CATEGORIES_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchError: null,
            };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                data: sortLexical(action.payload, "name", false, true),
            };
        case FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload,
                data: [],
            };

        case POST_CATEGORY_BEGIN:
            return {
                ...state,
                isPosting: true,
                postError: null,
            };
        case POST_CATEGORY_SUCCESS:
            return {
                ...state,
                isPosting: false,
                data: sortLexical([...state.data, action.payload], "name", false, true),
            };
        case POST_CATEGORY_FAILURE:
            return {
                ...state,
                isPosting: false,
                postError: action.payload,
            };

        case PUT_CATEGORY_BEGIN:
            return {
                ...state,
                isPutting: true,
                putError: null,
            };
        case PUT_CATEGORY_SUCCESS:
            let u_categories = [...state.data];
            u_categories.forEach((category, i) => {
                if (category._id === action.payload._id) {
                    u_categories[i] = action.payload;
                    return false;
                }
            });
            return {
                ...state,
                isPutting: false,
                data: sortLexical(u_categories, "name", false, true),
            };
        case PUT_CATEGORY_FAILURE:
            return {
                ...state,
                isPutting: false,
                putError: action.payload,
            };

        case DELETE_CATEGORY_BEGIN:
            return {
                ...state,
                isDeleting: true,
                deleteError: null,
            };
        case DELETE_CATEGORY_SUCCESS:
            let d_categories = [...state.data].filter((category) => {
                return category._id !== action.payload;
            });
            return {
                ...state,
                isDeleting: false,
                data: sortLexical(d_categories, "name", false, true),
            };
        case DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                isDeleting: false,
                deleteError: action.payload,
            };
        default:
            return state;
    }
}

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

import settings from "utils/settings";

import { Button } from "components/ReactBootstrapWrappers";

const Format = ({ value, onChange, idPrefix, ...rest }) => {
    const onCopy = async () => {
        try {
            await navigator.clipboard.writeText(value);
            toast("copied to clipboard");
        } catch (err) {
            toast.error("failed to copy text to clipboard");
        }
    };

    return (
        <div className="editor-header-option" {...rest}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`${idPrefix}-tooltip-json-format`}>copy to clipboard</Tooltip>}
                delay={{ show: settings.tooltipTimeout, hide: 0 }}
            >
                <Button
                    variant="outline-dark"
                    onClick={onCopy}
                    style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                >
                    <i className="fal fa-clipboard" />
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default Format;

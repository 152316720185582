import React from "react";
import SplitPane from "react-split-pane";

import useEditors from "./useEditors";
import PrimaryEditor from "./PrimaryEditor";
import ScratchpadEditor from "./ScratchpadEditor";

const Editors = () => {
    const { options, onEditorSizeChange } = useEditors();

    return (
        <div className="editor-page content-page">
            <SplitPane
                split="vertical"
                primary="second"
                maxSize={-200}
                minSize={200}
                size={options.panelSize}
                onChange={onEditorSizeChange}
                className="editor-splitter"
            >
                <PrimaryEditor />
                <ScratchpadEditor show={options.panelSize > 0} />
            </SplitPane>
        </div>
    );
};

export default Editors;

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import settings from "utils/settings";
import usePrimaryEditorHeader from "./usePrimaryEditorHeader";

import Mode from "components/Editor/EditorOptions/Mode";
import Gutter from "components/Editor/EditorOptions/Gutter";
import Wrap from "components/Editor/EditorOptions/Wrap";
import FontSize from "components/Editor/EditorOptions/FontSize";
import EditorFunctions from "components/Editor/EditorFunctions";
import { Button } from "components/ReactBootstrapWrappers";
import SaveSnippet from "./SaveSnippet";
import NewSnippet from "./NewSnippet";

const PrimaryEditorHeader = () => {
    const { snippet, options, sidebar, onOptionChange, onScratchpadToggle, onSidebarToggle, onValueReset } = usePrimaryEditorHeader();

    return (
        <div className="editor-header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-start">
                <div className="editor-header-option" style={{ marginRight: "2px" }}>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="primary-tooltip-scratchpad">toggle sidebar</Tooltip>}
                        delay={{ show: settings.tooltipTimeout, hide: 0 }}
                    >
                        <Button
                            variant="outline-dark"
                            onClick={onSidebarToggle}
                            style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                        >
                            {sidebar.size > 0 ? <i className="far fa-angle-left" /> : <i className="far fa-angle-right" />}
                        </Button>
                    </OverlayTrigger>
                </div>

                <div style={{ marginRight: "2px" }}>
                    <NewSnippet onValueReset={onValueReset} />
                </div>
                <div style={{ marginRight: "2px" }}>
                    <SaveSnippet />
                </div>
                <EditorFunctions value={snippet.value} onChange={onValueReset} mode={options.mode} idPrefix="primary" />
            </div>
            <div className="d-flex align-items-center justify-content-end">
                <FontSize value={options.fontSize} onChange={onOptionChange} />
                <Gutter value={options.showGutter} onChange={onOptionChange} idPrefix="primary" />
                <Wrap value={options.wrapEnabled} onChange={onOptionChange} style={{ marginLeft: "2px" }} idPrefix="primary" />
                <Mode value={options.mode} onChange={onOptionChange} style={{ marginLeft: "2px" }} />

                <div className="editor-header-option" style={{ marginLeft: "2px" }}>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="primary-tooltip-scratchpad">toggle scratchpad</Tooltip>}
                        delay={{ show: settings.tooltipTimeout, hide: 0 }}
                    >
                        <Button
                            variant="outline-dark"
                            onClick={onScratchpadToggle}
                            style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                        >
                            {options.panelSize > 0 ? <i className="far fa-angle-right" /> : <i className="far fa-angle-left" />}
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
        </div>
    );
};

export default PrimaryEditorHeader;

import React from "react";
import { Form, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { css } from "emotion";

import { appName } from "utils/constants";

import { Button } from "components/ReactBootstrapWrappers";
import useLogin from "./useLogin";

const Login = () => {
    const { onChange, onKeyPress, onSubmit, hideError, formData, errorMsg, theme } = useLogin();

    return (
        <div className="content-page">
            <div className="page-content">
                <div className="page-wrapper d-flex align-items-center justify-content-center">
                    <div className="w-100" style={{ maxWidth: "400px" }}>
                        <div className="d-flex align-items-center mb-1" style={{ fontSize: "1.25rem" }}>
                            login://{appName.toLowerCase()}
                            <span className="blinking-cursor">_</span>
                        </div>
                        {errorMsg !== null && (
                            <Alert variant="danger" dismissible={true} onClose={hideError} className="mb-2">
                                <div className="custom-close" onClick={hideError}>
                                    <i className="fal fa-times" />
                                </div>
                                {errorMsg}
                            </Alert>
                        )}
                        <Form.Group controlId="theme-colour" className="mb-2">
                            <Form.Control
                                className="mb-2"
                                type="text"
                                placeholder="username / email"
                                value={formData.username || ""}
                                name="username"
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                                autoFocus
                            />
                            <Form.Control
                                className="mb-2"
                                type="password"
                                placeholder="password"
                                value={formData.password || ""}
                                name="password"
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                            <Button variant="outline-primary" className="w-100" onClick={onSubmit}>
                                log in
                            </Button>
                        </Form.Group>
                        <div className="text-muted">
                            don't have an account?{" "}
                            <Link
                                to="/register"
                                className={css({
                                    color: "#676767",
                                    "&:hover, &:focus, &:active": {
                                        color: theme.primary,
                                    },
                                })}
                            >
                                register
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;

import React from "react";

const Loader = () => {
    return (
        // <div className="loader-container">
        <div className="cell">
            <div className="loader square switch">
                <div className="block">
                    <div className="box" />
                </div>
            </div>
        </div>
        // </div>
    );
};

export default Loader;

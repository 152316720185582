import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { clickInsideElement } from "utils/util";
import history from "utils/history";
import { toggleContextMenuOff } from "reduxStore/context-menu/context-menu.actions";
import { deleteSnippet, cloneSnippet } from "reduxStore/snippets/snippets.actions";
import { fetchSnippet, setOption, resetSnippet } from "reduxStore/snippet-page/snippet-page.actions";

const useSnippetContextMenu = () => {
    const { sidebarSnippet } = useSelector((state) => state.contextMenu);
    const { primaryEditorRef, snippet } = useSelector((state) => state.snippetPage);
    const { data: categories } = useSelector((state) => state.categories);
    const { data: subcategories } = useSelector((state) => state.subcategories);
    const { show, position } = sidebarSnippet;
    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener("contextmenu", (e) => {
            if (!clickInsideElement(e, "sidebar-snippet")) {
                dispatch(toggleContextMenuOff("sidebarSnippet"));
            }
        });
        document.addEventListener("click", (e) => {
            let button = e.which || e.button;
            if (button === 1) {
                dispatch(toggleContextMenuOff("sidebarSnippet"));
            }
        });
        window.onkeydown = (e) => {
            if (e.which === 27) {
                dispatch(toggleContextMenuOff("sidebarSnippet"));
            }
        };
    }, [dispatch]);

    const onOpenSnippet = async () => {
        if (!sidebarSnippet?.data?.snippet?._id) {
            return;
        }

        const res = await dispatch(fetchSnippet({ id: sidebarSnippet.data.snippet._id }));
        if (res.success) {
            primaryEditorRef?.current && primaryEditorRef.current.setValue(res?.snippet?.value || "");
            dispatch(setOption({ name: "mode", value: res.snippet?.mode }));
            history.push({
                pathname: "/snippets",
                search: `?id=${res.snippet._id}`,
            });
        }
    };

    const onCopySnippet = () => {
        const snippetId = sidebarSnippet?.data?.snippet?._id;
        if (!snippetId) {
            return;
        }

        dispatch(cloneSnippet({ id: snippetId, categories, subcategories }));
    };

    const onDeleteSnippet = async () => {
        const snippetId = sidebarSnippet?.data?.snippet?._id;
        if (!snippetId) {
            return;
        }

        const success = await dispatch(deleteSnippet({ id: snippetId, categories, subcategories }));
        if (success) {
            toast(`snippet "${sidebarSnippet.data.snippet.name}" deleted.`);
        }
        if (snippetId === snippet._id) {
            primaryEditorRef?.current && primaryEditorRef.current.setValue("");
            dispatch(resetSnippet());
            history.push({
                pathname: "/snippets",
                search: "",
            });
        }
    };

    return {
        show,
        position,
        onOpenSnippet,
        onCopySnippet,
        onDeleteSnippet,
    };
};

export default useSnippetContextMenu;

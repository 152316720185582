import { useState, useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useDispatch } from "react-redux";

import settings from "utils/settings";
import {
    getScratchpadOptions,
    setScratchpadOptions as setLocalOptions,
    DEFAULT_SCRATCHPAD_OPTIONS,
    getScratchpadValue,
    setScratchpadValue,
} from "../editorOptions";
import { setScratchpadEditorRef } from "reduxStore/snippet-page/snippet-page.actions";

const useScratchpadEditor = () => {
    const dispatch = useDispatch();

    const innerRef = useRef();

    useEffect(() => {
        if (innerRef) {
            dispatch(setScratchpadEditorRef(innerRef));

            let defaultValue = getScratchpadValue();
            setTimeout(() => innerRef?.current && innerRef.current.setValue(defaultValue), 0);
        }
    }, [innerRef, dispatch]);

    const [options, setOptions] = useState(DEFAULT_SCRATCHPAD_OPTIONS);

    useEffect(() => {
        try {
            let storageOptions = getScratchpadOptions();
            let mergedOptions = mergeOptions({ storageOptions, localOptions: DEFAULT_SCRATCHPAD_OPTIONS });
            setOptions(mergedOptions);
        } catch {}
    }, []);

    const mergeOptions = ({ storageOptions, localOptions }) => {
        let mergedOptions = {};
        Object.keys(localOptions).forEach((k) => {
            const v = localOptions[k];
            if (storageOptions?.[k] !== undefined) {
                mergedOptions[k] = storageOptions[k];
            } else mergedOptions[k] = v;
        });
        return mergedOptions;
    };

    useEffect(() => {
        setLocalOptions(options);
    }, [options]);

    const onOptionsChange = (options) => {
        setOptions(options);
    };

    const [value, setValue] = useState("");

    const [debounceSetValue] = useDebouncedCallback(setScratchpadValue, settings.debounce.typing);

    const onValueChange = (value) => {
        setValue(value);
        debounceSetValue(value);
    };

    const onValueReset = (value) => {
        onValueChange(value);
        innerRef?.current && innerRef.current.setValue(value);
    };

    return {
        value,
        options,
        onOptionsChange,
        onValueChange,
        onValueReset,
        innerRef,
    };
};

export default useScratchpadEditor;

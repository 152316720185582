import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import useReduxDataPosting from "utils/hooks/useReduxDataPosting";
import { postSquad } from "reduxStore/squads/squads.actions";

const useSquads = () => {
    const { data: squads, fetchError, leaveError } = useSelector((state) => state.squads);
    const { postData, postError } = useReduxDataPosting(postSquad, "squads");
    const { putError, deleteError } = useSelector((state) => state.squads);
    const authData = useSelector((state) => state.auth.data);
    const userId = authData?.user?._id;

    useEffect(() => {
        if (fetchError) {
            toast.error("an unknown error has occurred when getting squads.");
        }
        if (postError) {
            if (postError?.response?.data?.message === "duplicate key error") {
                toast.error("error: a squad with that name already exists.");
            } else {
                toast.error("an unknown error has occurred when adding a squad.");
            }
        }
        if (putError) {
            if (putError?.response?.data?.message === "duplicate key error") {
                toast.error("error: a squad with that name already exists.");
            } else {
                toast.error("an unknown error has occurred when updating a squad.");
            }
        }
        if (deleteError) {
            toast.error("an unknown error has occurred when deleting a squad.");
        }
        if (leaveError) {
            toast.error("an unknown error has occurred when leaving a squad.");
        }
    }, [fetchError, postError, putError, deleteError, leaveError]);

    const [squad, setSquad] = useState({ users: [userId] });
    const [mySquads, setMySquads] = useState([]);
    const [squadsImIn, setSquadsImIn] = useState([]);

    useEffect(() => {
        if (userId) {
            let mySquads = [];
            let squadsImIn = [];
            squads.forEach((squad) => {
                if (squad.uid === userId) {
                    mySquads.push(squad);
                } else {
                    squadsImIn.push(squad);
                }
            });
            setMySquads(mySquads);
            setSquadsImIn(squadsImIn);
        }
    }, [squads, userId]);

    const onChange = (evt) => {
        const { value, name } = evt.target;
        setSquad({ ...squad, [name]: value });
    };

    const addSquad = async () => {
        const success = await postData(squad);
        if (success) {
            toast.success(`squad "${squad.name}" added.`.toLowerCase());
            setSquad({ users: [userId] });
        }
    };

    const onKeyPress = (e) => {
        if (e.which === 13) {
            onAddClicked();
        }
    };

    const onAddClicked = async () => {
        if (!squad.name) return;
        addSquad();
    };

    return {
        // fns
        onChange,
        onAddClicked,
        onKeyPress,

        // data
        mySquads,
        squadsImIn,
        squad,
    };
};

export default useSquads;

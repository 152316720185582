import React from "react";
import { Form } from "react-bootstrap";

import { Button } from "components/ReactBootstrapWrappers";
import CategoryEntry from "./CategoryEntry";
import useSubcategories from "./useSubcategories";

const Subcategories = () => {
    const { onChange, onAddClicked, onKeyPress, subcategories, subcategory } = useSubcategories();

    return (
        <div className="page-section">
            <h4>subcategories</h4>
            <div className="page-group pt-2">
                <Form.Group>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            placeholder="name"
                            value={subcategory.name || ""}
                            name="name"
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            data-lpignore="true"
                        />
                        <Button variant="outline-primary" className="flex-btn ml-2" disabled={!subcategory.name} onClick={onAddClicked}>
                            <i className="fal fa-plus mr-2" />
                            add
                        </Button>
                    </div>
                </Form.Group>
                {subcategories.map((subcategory) => (
                    <CategoryEntry key={subcategory._id} subcategory={subcategory} />
                ))}
            </div>
        </div>
    );
};

export default Subcategories;

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import useReduxDataPosting from "utils/hooks/useReduxDataPosting";
import { postSettings } from "reduxStore/settings/settings.actions";
import { resetOptions, resetScratchpadOptions } from "views/Snippets/Editors/editorOptions";
import { resetSidebar } from "views/Snippets/Sidebar/sidebarOptions";

const useSettings = () => {
    const { data: storedSettings, postData, postError } = useReduxDataPosting(postSettings, "settings");

    useEffect(() => {
        if (postError) {
            toast.error("an unknown error has occurred when updating settings.");
        }
    }, [postError]);

    useEffect(() => {
        setSettings(storedSettings);
    }, [storedSettings]);

    const [settings, setSettings] = useState(storedSettings);

    const onChange = (evt) => {
        const { value, name } = evt.target;
        setSettings({ ...settings, [name]: value });
    };

    const updateSettings = async () => {
        const success = await postData(settings);
        if (success) {
            toast(`settings updated.`);
        }
    };

    const onUpdateClicked = async () => {
        let validation = validateSettings(settings);
        if (!validation.isValid) {
            validation.errors.forEach((err) => {
                toast.error(`${err.field}: ${err.message}`.toLowerCase());
            });
            return;
        }
        updateSettings();
    };

    const validateSettings = (settings) => {
        const results = {
            isValid: true,
            errors: [],
        };
        let themeColourValid = /^#([0-9a-f]{3}){1,2}$/i.test(settings.themeColour);
        if (!themeColourValid) {
            results.isValid = false;
            results.errors.push({ field: "Theme colour", message: "not a valid hex colour!" });
        }
        return results;
    };

    const resetLocalData = () => {
        resetOptions();
        resetScratchpadOptions();
        resetSidebar();
    };

    return {
        // fns
        onChange,
        onUpdateClicked,
        resetLocalData,

        // data
        settings,
    };
};

export default useSettings;

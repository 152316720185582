import client from "./axios";

/**
 * Category methods
 */

export const getCategories = () => {
    return client({
        method: "get",
        url: "/categories",
    });
};

export const addCategory = ({ item }) => {
    return client({
        method: "post",
        url: "/categories",
        data: item,
    });
};

export const updateCategory = ({ id, item }) => {
    return client({
        method: "put",
        url: `/categories/${id}`,
        data: item,
    });
};

export const removeCategory = async ({ id }) => {
    return client({
        method: "delete",
        url: `/categories/${id}`,
    });
};

/**
 * Subcategory methods
 */

export const getSubcategories = () => {
    return client({
        method: "get",
        url: "/subcategories",
    });
};

export const addSubcategory = ({ item }) => {
    return client({
        method: "post",
        url: "/subcategories",
        data: item,
    });
};

export const updateSubcategory = ({ id, item }) => {
    return client({
        method: "put",
        url: `/subcategories/${id}`,
        data: item,
    });
};

export const removeSubcategory = async ({ id }) => {
    return client({
        method: "delete",
        url: `/subcategories/${id}`,
    });
};

/**
 * Label methods
 */

export const getLabels = async () => {
    return client({
        method: "get",
        url: "/labels",
    });
};

export const addLabel = async ({ item }) => {
    return client({
        method: "post",
        url: "/labels",
        data: item,
    });
};

export const updateLabel = async ({ id, item }) => {
    return client({
        method: "put",
        url: `/labels/${id}`,
        data: item,
    });
};

export const removeLabel = async ({ id }) => {
    return client({
        method: "delete",
        url: `/labels/${id}`,
    });
};

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

import settings from "utils/settings";

import { Button } from "components/ReactBootstrapWrappers";

const Wrap = ({ value, onChange, idPrefix, ...rest }) => {
    const theme = useSelector((state) => state.theme);

    const onWrapChange = () => {
        onChange({ name: "wrapEnabled", value: !value });
    };

    return (
        <div className="editor-header-option" {...rest}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`${idPrefix}-tooltip-wrap`}>toggle line wrapping</Tooltip>}
                delay={{ show: settings.tooltipTimeout, hide: 0 }}
            >
                <Button
                    variant="outline-dark"
                    onClick={onWrapChange}
                    style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                >
                    {value ? <i className="fal fa-level-down fa-rotate-90" style={{ color: theme.primary }} /> : <i className="fal fa-long-arrow-right" />}
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default Wrap;

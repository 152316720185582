import { combineReducers } from "redux";

import auth from "./auth/auth.reducers";
import categories from "./categories/categories.reducers";
import subcategories from "./subcategories/subcategories.reducers";
import labels from "./labels/labels.reducers";
import settings from "./settings/settings.reducers";
import squads from "./squads/squads.reducers";
import theme from "./theme/theme.reducers";
import snippetPage from "./snippet-page/snippet-page.reducers";
import snippets from "./snippets/snippets.reducers";
import contextMenu from "./context-menu/context-menu.reducers";

export default combineReducers({
    auth,
    categories,
    subcategories,
    labels,
    settings,
    squads,
    theme,
    snippetPage,
    snippets,
    contextMenu,
});

import settings from "utils/settings";
import Color from "color";

import { SET_THEME, SET_RANDOM_THEME } from "./theme.actions";

const initialState = {
    ...settings.theme,
};

// Ratios for modifying primary
const primary_dark1_ratio = 0.14;
const primary_dark2_ratio = 0.43;
const primary_light1_ratio = 0.3;
const primary_light2_ratio = 0.46;

function getThemeColours(primary) {
    return {
        primary,
        "primary-light-1": Color(primary).lighten(primary_light1_ratio).hex(),
        "primary-light-2": Color(primary).lighten(primary_light2_ratio).hex(),
        "primary-dark-1": Color(primary).darken(primary_dark1_ratio).hex(),
        "primary-dark-2": Color(primary).darken(primary_dark2_ratio).hex(),
    };
}
const PRIMARY = [
    "#fe7f2d",
    "#90323d",
    "#8c7a6b",
    "#a5668b",
    "#69306d",
    "#4cb944",
    "#1098f7",
    "#542344",
    "#3a3e3b",
    "#c97d60",
    "#ba1f33",
    "#488b49",
    "#7f9172",
    "#fb4b4e",
    "#39a0ed",
    "#4c6085",
    "#f08700",
    "#00a6a6",
    "#806d40",
    "#8b80f9",
    "#9e9885",
];

function getRandomPrimary() {
    return PRIMARY[Math.floor(Math.random() * PRIMARY.length)];
}

export default function themeReducer(state = initialState, action) {
    switch (action.type) {
        case SET_THEME:
            let { primary } = action.payload;

            return {
                ...action.payload,
                ...getThemeColours(primary),
            };
        case SET_RANDOM_THEME:
            let primary_r = getRandomPrimary();
            return {
                ...action.payload,
                ...getThemeColours(primary_r),
            };
        default:
            return state;
    }
}

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

import settings from "utils/settings";

import { Button } from "components/ReactBootstrapWrappers";

const RemoveComments = ({ value, onChange, idPrefix, ...rest }) => {
    const onRemoveComments = () => {
        onChange(value);
        try {
            let noCommentValue = value;
            // remove comments
            noCommentValue = noCommentValue.replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, "");
            onChange(noCommentValue);
        } catch (err) {
            toast.error("error: failed to remove comments.");
        }
    };

    return (
        <div className="editor-header-option" {...rest}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`${idPrefix}-tooltip-javascript-remove-comments`}>remove comments</Tooltip>}
                delay={{ show: settings.tooltipTimeout, hide: 0 }}
            >
                <Button
                    variant="outline-dark"
                    onClick={onRemoveComments}
                    style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                >
                    <i className="fal fa-comment-slash" />
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default RemoveComments;

import React from "react";
import Labels from "./Labels";
import Categories from "./Categories";
import Subcategories from "./Subcategories";

const Manage = () => {
    return (
        <div className="content-page">
            <div className="page-content">
                <div className="page-wrapper">
                    <Categories />
                    <Subcategories />
                    <Labels />
                </div>
            </div>
        </div>
    );
};

export default Manage;

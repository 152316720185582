import React from "react";

import Editor from "components/Editor";
import ScratchpadEditorHeader from "./ScratchpadEditorHeader";
import useScratchpadEditor from "./useScratchpadEditor";

const ScratchpadEditor = ({ show }) => {
    const { value, options, onOptionsChange, onValueChange, onValueReset, innerRef } = useScratchpadEditor();

    return (
        <>
            <ScratchpadEditorHeader value={value} options={options} onChange={onOptionsChange} onValueChange={onValueReset} show={show} />
            <Editor className="scratchpad-editor" onChange={onValueChange} options={options} ref={innerRef} style={{ display: show ? "block" : "none" }} />
        </>
    );
};

export default ScratchpadEditor;

import { useEffect, useState, useImperativeHandle } from "react";
import ace from "ace-builds/src-noconflict/ace";
import { uuid } from "uuidv4";

import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-scss";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-text";

import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/webpack-resolver";

const useEditor = ({ onChange, options }, ref) => {
    const [uniqueId] = useState(uuid());
    const [editor, setEditor] = useState(null);

    useImperativeHandle(ref, () => ({
        forceResize() {
            resize();
        },
        setValue(value) {
            editor && editor.session.setValue(value);
        },
    }));

    // initialise the editor
    useEffect(() => {
        // set default values
        const editor = ace.edit(`editor-${uniqueId}`);
        editor.setTheme("ace/theme/twilight");

        // set initial value
        editor.session.setValue("");

        editor.setShowPrintMargin(options.showPrintMargin);
        editor.session.setUseSoftTabs(true);
        editor.session.setTabSize(4);

        // set listeners
        editor.session.on("change", () => {
            onChange(editor.getValue());
        });

        setEditor(editor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resize = () => {
        if (!editor) return;
        editor.resize(true);
    };

    // handlers for option changes
    useEffect(() => {
        if (!editor) return;
        editor.session.setMode(`ace/mode/${options.mode || "json"}`);
    }, [editor, options.mode]);

    useEffect(() => {
        if (!editor) return;
        editor.renderer.setShowGutter(options.showGutter);
    }, [editor, options.showGutter]);

    useEffect(() => {
        if (!editor) return;
        editor.session.setUseWrapMode(options.wrapEnabled);
    }, [editor, options.wrapEnabled]);

    useEffect(() => {
        if (!editor || !uniqueId) return;
        document.getElementById(`editor-${uniqueId}`).style.fontSize = `${options.fontSize}px`;
    }, [editor, options.fontSize, uniqueId]);

    return { uniqueId, resize };
};

export default useEditor;

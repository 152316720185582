import { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSnippetAttribute, setPrimaryEditorRef } from "reduxStore/snippet-page/snippet-page.actions";

const usePrimaryEditor = () => {
    const { options } = useSelector((state) => state.snippetPage);
    const dispatch = useDispatch();

    const innerRef = useRef();

    useEffect(() => {
        if (innerRef) {
            dispatch(setPrimaryEditorRef(innerRef));
        }
    }, [innerRef, dispatch]);

    const onSnippetValueChange = (value) => {
        dispatch(setSnippetAttribute({ name: "value", value: value }));
    };

    return {
        onSnippetValueChange,
        options,
        innerRef,
    };
};

export default usePrimaryEditor;

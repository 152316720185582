import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { css } from "emotion";
import classNames from "classnames";

import { appVersion } from "utils/constants";

import { Button } from "components/ReactBootstrapWrappers";
import useSettings from "./useSettings";

const ColourIndicator = React.memo((props) => {
    const [colour, setColour] = useState(null);

    useEffect(() => {
        let isColorValid = /^#([0-9a-f]{3}){1,2}$/i.test(props.colour);
        setColour(isColorValid ? props.colour : null);
    }, [props.colour]);

    return (
        <div
            className="ml-2 text-muted border border-dark"
            style={{
                width: "35px",
                background: colour !== null ? colour : "transparent",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {colour === null && <i className="fal fa-times" />}
        </div>
    );
});

const Settings = () => {
    const { onChange, onUpdateClicked, resetLocalData, settings } = useSettings();

    return (
        <div className="content-page">
            <div className="app-version text-muted">v{appVersion}</div>
            <div className="page-content">
                <div
                    className={classNames(
                        "page-wrapper",
                        css({
                            ".page-section:nth-last-child(2)": {
                                marginBottom: `0 !important`,
                            },
                        })
                    )}
                >
                    <div className="page-section">
                        <h4>general</h4>
                        <div className="page-group">
                            <Form.Group controlId="theme-colour">
                                <Form.Label>theme colour</Form.Label>
                                <div style={{ display: "flex" }}>
                                    <Form.Control type="text" placeholder="#bdea74" value={settings.themeColour} name="themeColour" onChange={onChange} />
                                    <ColourIndicator colour={settings.themeColour} />
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="page-section">
                        <div className="page-group">
                            <Button variant="outline-primary" onClick={onUpdateClicked} className="form-control">
                                save
                            </Button>
                        </div>
                    </div>

                    <div className="page-section">
                        <h4>reset local data</h4>
                        <div className="page-group">
                            <Form.Group controlId="theme-colour">
                                <Form.Label>reload the page after clicking this</Form.Label>
                                <div>
                                    <Button variant="outline-danger" onClick={resetLocalData}>
                                        <i className="fal fa-sync mr-2" />
                                        reset
                                    </Button>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;

import React, { useState } from "react";
import classNames from "classnames";

import Snippet from "../Snippet";
import LoadMore from "../LoadMore";

const Subcategory = ({ subcategory, categoryId, hide, loadSnippet, activeSnippet }) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleKeyPress = (e) => {
        if (e.which === 13 || e.which === 32) {
            toggleCollapse();
            e.preventDefault();
        }
    };

    return (
        <div className="sidebar-item sidebar-subcategory">
            <div
                className={classNames("sidebar-item-toggle noselect", { "sidebar-toggle-closed": hide })}
                onClick={toggleCollapse}
                tabIndex={0}
                onKeyPress={handleKeyPress}
                id={`sub_${categoryId}-${subcategory._id}`}
            >
                <div className="toggle-title">
                    <i className={classNames("mr-2", collapsed ? "fal fa-angle-right" : "fal fa-angle-down")} style={{ minWidth: "7px" }} />
                    <i
                        className={classNames("fal fa-folder fa-sm mr-2", {
                            "text-primary": activeSnippet.category === categoryId && activeSnippet.subcategory === subcategory._id,
                        })}
                    />
                    {subcategory.name}
                </div>
            </div>
            <div className="sidebar-item-collapse sidebar-subcategory-collapse" style={{ display: collapsed ? "none" : "block" }}>
                {subcategory.snippets.map((snippet) => (
                    <Snippet key={snippet._id} snippet={snippet} hide={collapsed} loadSnippet={loadSnippet} activeSnippetId={activeSnippet?._id} />
                ))}
                {subcategory.count > subcategory.snippets.length && (
                    <LoadMore category={categoryId} subcategory={subcategory._id} count={subcategory.snippets.length} />
                )}
            </div>
        </div>
    );
};

export default Subcategory;

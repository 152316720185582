import { DEFAULT_OPTIONS } from "reduxStore/snippet-page/snippet-page.actions";

const OPTIONS_KEY = "editorSettings";

export const getOptions = () => {
    try {
        return JSON.parse(localStorage.getItem(OPTIONS_KEY));
    } catch (err) {
        return DEFAULT_OPTIONS;
    }
};

export const setOptions = (options) => {
    return localStorage.setItem(OPTIONS_KEY, JSON.stringify(options));
};

export const resetOptions = () => {
    setOptions(DEFAULT_OPTIONS);
};

const SCRATCHPAD_OPTIONS_KEY = "scratchpadEditorSettings";
const SCRATCHPAD_VALUE_KEY = "scratchpadEditorValue";
export const DEFAULT_SCRATCHPAD_OPTIONS = {
    wrapEnabled: true,
    showGutter: true,
    showPrintMargin: true,
    fontSize: 16,
    tabSize: 4,
    mode: "markdown",
    panelSize: 450,
};

export const getScratchpadOptions = () => {
    try {
        return JSON.parse(localStorage.getItem(SCRATCHPAD_OPTIONS_KEY));
    } catch (err) {
        return DEFAULT_SCRATCHPAD_OPTIONS;
    }
};

export const setScratchpadOptions = (options) => {
    return localStorage.setItem(SCRATCHPAD_OPTIONS_KEY, JSON.stringify(options));
};

export const getScratchpadValue = () => {
    return localStorage.getItem(SCRATCHPAD_VALUE_KEY) || "";
};

export const setScratchpadValue = (value) => {
    return localStorage.setItem(SCRATCHPAD_VALUE_KEY, value);
};

export const resetScratchpadOptions = () => {
    setScratchpadOptions(DEFAULT_SCRATCHPAD_OPTIONS);
};

import { useDispatch, useSelector } from "react-redux";

const useReduxDataDeleting = (dataSource, key) => {
    const { data, deleteError, isDeleting } = useSelector((state) => state[key]);
    const dispatch = useDispatch();

    const deleteData = async (id) => {
        return await dispatch(dataSource({ id }));
    };

    return {
        deleteData,
        deleteError,
        isDeleting,
        data,
    };
};

export default useReduxDataDeleting;

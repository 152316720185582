import {
    LOGIN_BEGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    CLEAR_LOGIN_ERROR,
    VERIFY_BEGIN,
    VERIFY_SUCCESS,
    VERIFY_FAILURE,
    REGISTER_BEGIN,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    CLEAR_REGISTER_ERROR,
} from "./auth.actions";

const initialState = {
    data: {},

    isLoggingIn: false,
    loginError: null,

    isVerifying: false,
    verifyError: null,

    isRegistering: false,
    registerError: null,
};

export default function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_BEGIN:
            return {
                ...state,
                isLoggingIn: true,
                loginError: null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                data: action.payload,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                loginError: action.payload,
                data: {},
            };
        case CLEAR_LOGIN_ERROR:
            return {
                ...state,
                loginError: null,
            };

        case VERIFY_BEGIN:
            return {
                ...state,
                isVerifying: true,
                loginError: null,
            };
        case VERIFY_SUCCESS:
            return {
                ...state,
                isVerifying: false,
                data: action.payload,
            };
        case VERIFY_FAILURE:
            return {
                ...state,
                isVerifying: false,
                loginError: action.payload,
                data: {},
            };

        case REGISTER_BEGIN:
            return {
                ...state,
                isRegistering: true,
                registerError: null,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                isRegistering: false,
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                isRegistering: false,
                registerError: action.payload,
            };
        case CLEAR_REGISTER_ERROR:
            return {
                ...state,
                registerError: null,
            };
        default:
            return state;
    }
}

import React from "react";
import classNames from "classnames";
import { css } from "emotion";
import { useSelector, useDispatch } from "react-redux";

import { fetchMoreSnippets } from "reduxStore/snippets/snippets.actions";

const LoadMore = ({ category, subcategory, count }) => {
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();

    const onLoadMore = () => {
        setNewFocus();
        dispatch(fetchMoreSnippets({ category, subcategory, offset: count }));
    };

    const handleKeyPress = (e) => {
        if (e.which === 13 || e.which === 32) {
            onLoadMore();
            e.preventDefault();
        }
    };

    const setNewFocus = () => {
        let navItemElements = document.querySelectorAll(".sidebar-item-toggle:not(.sidebar-toggle-closed)");
        let navItemIds = [];
        for (let item of navItemElements) {
            navItemIds.push(item.id);
        }

        // get the element that triggered the event, break early if element not in nav list
        let elementId = `lmo_${category}-${subcategory}`;
        if (!navItemIds.includes(elementId)) {
            return;
        }

        let newFocusId = subcategory ? `sub_${category}-${subcategory}` : `cat_${category}`;
        let newFocusIndex = navItemIds.indexOf(newFocusId);
        navItemElements[newFocusIndex].focus();
    };

    return (
        <div className="sidebar-item">
            <div
                onClick={onLoadMore}
                className={classNames(
                    "sidebar-item-toggle sidebar-load-more noselect",
                    css({
                        "&:focus": { color: `${theme.primary} !important` },
                    })
                )}
                tabIndex={0}
                onKeyPress={handleKeyPress}
                id={`lmo_${category}-${subcategory}`}
            >
                load more...
            </div>
        </div>
    );
};

export default LoadMore;

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import { fetchSnippets } from "reduxStore/snippets/snippets.actions";

const useSidebar = () => {
    const { sidebar, rawSnippet: activeSnippet, fetchError: snippetFetchError } = useSelector((state) => state.snippetPage);
    const { data: snippetCategories, hasFetched: hasFetchedSnippets, fetchError: snippetsFetchError } = useSelector((state) => state.snippets);
    const { data: categories, hasFetched: hasFetchedCategories } = useSelector((state) => state.categories);
    const { data: subcategories, hasFetched: hasFetchedSubcategories } = useSelector((state) => state.subcategories);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!hasFetchedSnippets && hasFetchedCategories && hasFetchedSubcategories) {
            dispatch(fetchSnippets({ categories, subcategories }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFetchedCategories, hasFetchedSubcategories]);

    useEffect(() => {
        if (snippetsFetchError) {
            toast.error("an unknown error has occurred when getting snippets.");
        }
        if (snippetFetchError) {
            toast.error("an unknown error has occurred when loading a snippet.");
        }
    }, [snippetsFetchError, snippetFetchError]);

    const handleNavigation = (e) => {
        if (![37, 38, 39, 40].includes(e.which)) {
            return;
        }

        // organise nav elements into an array of ids
        let navItemElements = document.querySelectorAll(".sidebar-item-toggle:not(.sidebar-toggle-closed)");
        let navItemIds = [];
        for (let item of navItemElements) {
            navItemIds.push(item.id);
        }

        // get the element that triggered the event, break early if element not in nav list
        let elementId = e.target.id;
        if (!navItemIds.includes(elementId)) {
            return;
        }

        // call relevant method based on key press
        let newFocusIndex = 0;
        switch (e.which) {
            case 40:
                newFocusIndex = focusNext({ id: elementId, ids: navItemIds });
                break;
            case 38:
                newFocusIndex = focusPrevious({ id: elementId, ids: navItemIds });
                break;
            case 39:
                newFocusIndex = focusNextGroup({ id: elementId, ids: navItemIds });
                break;
            case 37:
                newFocusIndex = focusPreviousGroup({ id: elementId, ids: navItemIds });
                break;
            default:
                newFocusIndex = navItemIds.indexOf(elementId);
                break;
        }

        navItemElements[newFocusIndex].focus();
        e.preventDefault();
        return false;
    };

    const focusNext = ({ id, ids }) => {
        let newIndex = ids.indexOf(id) + 1;
        if (newIndex >= ids.length) {
            return ids.length - 1;
        }
        return newIndex;
    };

    const focusPrevious = ({ id, ids }) => {
        let newIndex = ids.indexOf(id) - 1;
        if (newIndex < 0) {
            return 0;
        }
        return newIndex;
    };

    const focusNextGroup = ({ id, ids }) => {
        let currentIndex = ids.indexOf(id);
        for (let i = currentIndex + 1; i < ids.length; i++) {
            if (ids[i].startsWith("cat_") || ids[i].startsWith("sub_")) {
                currentIndex = i;
                break;
            }
        }
        return currentIndex;
    };

    const focusPreviousGroup = ({ id, ids }) => {
        let currentIndex = ids.indexOf(id);
        for (let i = currentIndex - 1; i >= 0; --i) {
            if (ids[i].startsWith("cat_") || ids[i].startsWith("sub_")) {
                currentIndex = i;
                break;
            }
        }
        return currentIndex;
    };

    return { sidebar, snippetCategories, handleNavigation, activeSnippet };
};

export default useSidebar;

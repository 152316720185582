import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const useReduxDataFetching = (dataSource, key) => {
    const { auth: isAuthenticated } = useSelector((state) => state.auth.data);
    const { data, fetchError, isFetching, hasFetched } = useSelector((state) => state[key]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated === true && !hasFetched) {
            dispatch(dataSource());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return {
        fetchError,
        isFetching,
        data,
    };
};

export default useReduxDataFetching;

import React from "react";
import { Modal, Form, Alert } from "react-bootstrap";
import Select from "react-select";

import useSelectStyles from "utils/hooks/useSelectStyles";

import useSaveSnippet from "./useSaveSnippet";
import Textarea from "components/Inputs/Textarea";
import { Button } from "components/ReactBootstrapWrappers";

const SaveSnippet = () => {
    const {
        snippet,
        options,
        showSaveModal,
        modeOptions,
        categoryOptions,
        subcategoryOptions,
        labelOptions,
        errors,
        onSaveClicked,
        onClose,
        onChange,
        onSelectChange,
        onMultiselectChange,
        onModeChange,
        handleSave,
        hideErrors,
    } = useSaveSnippet();

    const { styles, IndicatorSeparator, DropdownIndicator, ClearIndicator } = useSelectStyles({
        menuStyle: { zIndex: 10 },
        controlStyle: { minHeight: "35px" },
    });

    return (
        <>
            <Button variant="outline-dark" onClick={onSaveClicked} className="flex-btn">
                <i className="fal fa-arrow-up mr-2" />
                save
            </Button>
            <Modal show={showSaveModal} onHide={onClose} animation={false} size="lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{}}>save snippet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errors.length !== 0 && (
                        <Alert variant="danger" dismissible={true} onClose={hideErrors} className="mb-2">
                            <div className="custom-close" onClick={hideErrors}>
                                <i className="fal fa-times" />
                            </div>
                            <div>errors:</div>
                            {errors.map((err, i) => (
                                <div key={i}>
                                    {">"} {err}
                                </div>
                            ))}
                        </Alert>
                    )}
                    <div className="form-grid">
                        <div className="form-grid-item">
                            <Form.Label>
                                name<span className="text-muted ml-2">*</span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="name" value={snippet.name || ""} name="name" onChange={onChange} autoFocus />
                        </div>
                        <div className="form-grid-item">
                            <Form.Label>
                                mode<span className="text-muted ml-2">*</span>
                            </Form.Label>
                            <Select
                                value={modeOptions.find((option) => option.value === options.mode)}
                                options={modeOptions}
                                onChange={onModeChange}
                                placeholder="mode..."
                                styles={styles}
                                components={{ IndicatorSeparator, DropdownIndicator }}
                            />
                        </div>
                        <div className="form-grid-item">
                            <Form.Label>
                                category<span className="text-muted ml-2">*</span>
                            </Form.Label>
                            <Select
                                value={categoryOptions.find((option) => option.value === snippet.category)}
                                options={categoryOptions}
                                onChange={(option) => onSelectChange("category", option)}
                                placeholder="category..."
                                styles={styles}
                                components={{ IndicatorSeparator, DropdownIndicator }}
                            />
                        </div>
                        <div className="form-grid-item">
                            <Form.Label>subcategory</Form.Label>
                            <Select
                                value={subcategoryOptions.find((option) => option.value === snippet.subcategory)}
                                options={subcategoryOptions}
                                onChange={(option) => onSelectChange("subcategory", option)}
                                placeholder="subcategory..."
                                styles={styles}
                                components={{ IndicatorSeparator, DropdownIndicator, ClearIndicator }}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-grid-item full-width">
                            <Form.Label>labels</Form.Label>
                            <Select
                                isMulti={true}
                                value={labelOptions.filter((option) => snippet.labels.includes(option.value))}
                                options={labelOptions}
                                onChange={(option) => onMultiselectChange("labels", option)}
                                placeholder="labels..."
                                styles={styles}
                                components={{ IndicatorSeparator, DropdownIndicator, ClearIndicator }}
                                isClearable={true}
                            />
                        </div>
                        <div className="form-grid-item full-width">
                            <Form.Label>notes</Form.Label>
                            <Textarea value={snippet.notes || ""} placeholder="notes" name="notes" onChange={onChange} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-light" onClick={onClose}>
                        close
                    </Button>
                    <Button variant="outline-primary" onClick={handleSave}>
                        save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SaveSnippet;

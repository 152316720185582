import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import prettier from "prettier/standalone";
import prettierScssParser from "prettier/parser-postcss";

import settings from "utils/settings";

import { Button } from "components/ReactBootstrapWrappers";

const Format = ({ value, onChange, idPrefix, ...rest }) => {
    const onFormat = () => {
        onChange(value);
        try {
            let beautified = prettier.format(value, {
                parser: "scss",
                plugins: [prettierScssParser],
                tabWidth: 4,
                printWidth: 80,
                semi: true,
            });
            onChange(beautified);
        } catch (err) {
            toast.error("error: unknown error occurred when beautifying scss.");
        }
    };

    return (
        <div className="editor-header-option" {...rest}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`${idPrefix}-tooltip-scss-format`}>beautify scss</Tooltip>}
                delay={{ show: settings.tooltipTimeout, hide: 0 }}
            >
                <Button
                    variant="outline-dark"
                    onClick={onFormat}
                    style={{ height: "35px", width: "35px", minWidth: "35px", alignItems: "center", justifyContent: "center", padding: 0 }}
                >
                    <i className="fal fa-indent" />
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default Format;

import React from "react";
import FormatJSON from "./JSON/Format";
import UglifyJSON from "./JSON/Uglify";
import FormatJavascript from "./Javascript/Format";
import UglifyJavascript from "./Javascript/Uglify";
import RemoveComments from "./Javascript/RemoveComments";
import FormatSCSS from "./SCSS/Format";
import Copy from "./Common/Copy";

const EditorFunctions = ({ value, onChange, mode, idPrefix }) => {
    if (mode === "json") {
        return (
            <>
                <FormatJSON value={value} onChange={onChange} style={{ marginRight: "2px" }} idPrefix={idPrefix} />
                <UglifyJSON value={value} onChange={onChange} style={{ marginRight: "2px" }} idPrefix={idPrefix} />
                <Copy value={value} />
            </>
        );
    }
    if (mode === "javascript") {
        return (
            <>
                <FormatJavascript value={value} onChange={onChange} style={{ marginRight: "2px" }} idPrefix={idPrefix} />
                <UglifyJavascript value={value} onChange={onChange} style={{ marginRight: "2px" }} idPrefix={idPrefix} />
                <RemoveComments value={value} onChange={onChange} style={{ marginRight: "2px" }} idPrefix={idPrefix} />
                <Copy value={value} idPrefix={idPrefix} />
            </>
        );
    }
    if (mode === "scss") {
        return (
            <>
                <FormatSCSS value={value} onChange={onChange} style={{ marginRight: "2px" }} idPrefix={idPrefix} />
                <Copy value={value} idPrefix={idPrefix} />
            </>
        );
    }
    if (mode === "sql") {
        return (
            <>
                <Copy value={value} idPrefix={idPrefix} />
            </>
        );
    }
    if (mode === "text" || mode === "markdown") {
        return (
            <>
                <Copy value={value} idPrefix={idPrefix} />
            </>
        );
    }
    return null;
};

export default EditorFunctions;

import React from "react";
import { Modal } from "react-bootstrap";

import { Button } from "components/ReactBootstrapWrappers";
import useNewSnippet from "./useNewSnippet";

const NewSnippet = ({ onValueReset }) => {
    const { showNewModal, onNewClicked, setNewSnippet, onClose } = useNewSnippet({ onValueReset });

    return (
        <>
            <Button variant="outline-dark" onClick={onNewClicked} className="flex-btn">
                <i className="fal fa-plus mr-2" />
                new
            </Button>
            <Modal show={showNewModal} onHide={onClose} animation={false} size="lg" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title style={{}}>new snippet</Modal.Title>
                </Modal.Header>
                <Modal.Body>you have unsaved changes. do you wish to discard them?</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-light" onClick={onClose} autoFocus>
                        cancel
                    </Button>
                    <Button variant="outline-primary" onClick={setNewSnippet}>
                        yes, discard
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NewSnippet;

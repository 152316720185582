import React, { useState } from "react";
import { components } from "react-select";

const IndicatorSeparator = () => null;
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="far fa-angle-down" />
        </components.DropdownIndicator>
    );
};
const ClearIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="far fa-times" />
        </components.DropdownIndicator>
    );
};

const useSelectStyles = ({ menuStyle, controlStyle }) => {
    const [styles] = useState({
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            borderRadius: 0,
            borderColor: "#4d4d4d",
            boxShadow: "none",
            "&:focus,&:focus-within": {
                borderColor: "#c7c7c7",
            },
            "&:hover": {
                borderColor: "#4d4d4d",
            },
            minHeight: controlStyle?.minHeight || null,
        }),
        valueContainer: (provided) => ({
            ...provided,
            cursor: "text",
        }),
        input: (provided) => ({
            ...provided,
            color: "inherit",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#4d4d4d",
        }),
        loadingIndicator: (provided) => ({
            ...provided,
            color: "#c7c7c7",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isFocused ? "inherit" : "#4d4d4d",
            "&:hover": {
                color: "inherit",
            },
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: "none",
            border: "1px solid #c7c7c7",
            borderRadius: 0,
            backgroundColor: "#141414",
            zIndex: menuStyle?.zIndex || null,
        }),
        menuList: (provided) => ({
            ...provided,
            paddingBottom: "0",
            paddingTop: "0",
        }),
        option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            borderRadius: 0,
            backgroundColor: isSelected ? "#4d4d4d" : isFocused ? "#333333" : "transparent",
            color: "inherit",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "inherit",
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: 0,
            border: "1px solid #c7c7c7",
            background: "transparent",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            padding: "2px",
            color: "#c7c7c7",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            borderRadius: 0,
        }),
    });
    return { styles, IndicatorSeparator, DropdownIndicator, ClearIndicator };
};

export default useSelectStyles;

import { useDispatch, useSelector } from "react-redux";

const useReduxDataPutting = (dataSource, key) => {
    const { data, putError, isPutting } = useSelector((state) => state[key]);
    const dispatch = useDispatch();

    const putData = async ({ id, item }) => {
        return await dispatch(dataSource({ id, item }));
    };

    return {
        putData,
        putError,
        isPutting,
        data,
    };
};

export default useReduxDataPutting;

import React from "react";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { css } from "emotion";

import ROUTES from "utils/routes";

import CustomDropdownToggle from "components/CustomDropdownToggle";

const NavigatorDropdown = (props) => {
    const theme = useSelector((state) => state.theme);
    return (
        <Dropdown className="app-navigator">
            <Dropdown.Toggle as={CustomDropdownToggle}>
                <div className="app-navigator-toggle noselect">
                    <i className={classNames("mr-2", ROUTES?.[props.location.pathname]?.icon || null)} style={{ color: theme.primary }} />
                    {ROUTES?.[props.location.pathname]?.name || null}
                    <i className="fal fa-angle-down ml-2 dropdown-icon" />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {Object.keys(ROUTES).map((route) => (
                    <Dropdown.Item
                        as={Link}
                        to={route}
                        className={classNames(
                            "d-flex",
                            { active: props.location.pathname === route },
                            css({
                                "&.active i": {
                                    color: theme.primary,
                                },
                            })
                        )}
                        key={route}
                    >
                        <div className="mr-2 d-flex justify-content-center align-items-center" style={{ minWidth: "20px" }}>
                            <i className={classNames(ROUTES[route].icon)} />
                        </div>
                        {ROUTES[route].name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default withRouter(NavigatorDropdown);

import { useSelector, useDispatch } from "react-redux";

import { setNewModal, onNew } from "reduxStore/snippet-page/snippet-page.actions";
import { isEqualEntity } from "utils/util";

const useNewSnippet = ({ onValueReset }) => {
    const { snippet, rawSnippet, options, showNewModal } = useSelector((state) => state.snippetPage);
    const dispatch = useDispatch();

    const onNewClicked = () => {
        if (isEqualEntity({ ...snippet, mode: options.mode }, { ...rawSnippet, mode: rawSnippet.mode || options.mode })) {
            dispatch(onNew());
            onValueReset("");
        } else {
            dispatch(setNewModal(true));
        }
    };

    const setNewSnippet = () => {
        dispatch(onNew());
        onValueReset("");
    };

    const onClose = () => {
        dispatch(setNewModal(false));
    };

    return {
        showNewModal,
        onNewClicked,
        setNewSnippet,
        onClose,
    };
};

export default useNewSnippet;

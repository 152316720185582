import { useSelector, useDispatch } from "react-redux";
import { changeSquad } from "reduxStore/squads/squads.actions";

const useSquadsDropdown = () => {
    const dispatch = useDispatch();
    const { data: squads, squad: selectedSquad } = useSelector((state) => state.squads);

    const onChange = (squad) => {
        if (!squad || squad._id !== selectedSquad?._id) {
            dispatch(changeSquad(squad));
        }
    };

    return {
        onChange,
        squads,
        selectedSquad,
    };
};

export default useSquadsDropdown;

import {
    RESET_LABELS,
    FETCH_LABELS_BEGIN,
    FETCH_LABELS_SUCCESS,
    FETCH_LABELS_FAILURE,
    POST_LABEL_BEGIN,
    POST_LABEL_SUCCESS,
    POST_LABEL_FAILURE,
    PUT_LABEL_BEGIN,
    PUT_LABEL_SUCCESS,
    PUT_LABEL_FAILURE,
    DELETE_LABEL_BEGIN,
    DELETE_LABEL_SUCCESS,
    DELETE_LABEL_FAILURE,
} from "./labels.actions";
import { sortLexical } from "utils/util";

const initialState = {
    data: [],

    hasFetched: false,
    isFetching: false,
    fetchError: null,

    isPosting: false,
    postError: null,

    isPutting: false,
    putError: null,

    isDeleting: false,
    deleteError: null,
};

export default function labelsReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_LABELS:
            return initialState;
        case FETCH_LABELS_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchError: null,
            };
        case FETCH_LABELS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasFetched: true,
                data: sortLexical(action.payload, "name", false, true),
            };
        case FETCH_LABELS_FAILURE:
            return {
                ...state,
                isFetching: false,
                fetchError: action.payload,
                items: [],
            };

        case POST_LABEL_BEGIN:
            return {
                ...state,
                isPosting: true,
                postError: null,
            };
        case POST_LABEL_SUCCESS:
            return {
                ...state,
                isPosting: false,
                data: sortLexical([...state.data, action.payload], "name", false, true),
            };
        case POST_LABEL_FAILURE:
            return {
                ...state,
                isPosting: false,
                postError: action.payload,
            };

        case PUT_LABEL_BEGIN:
            return {
                ...state,
                isPutting: true,
                putError: null,
            };
        case PUT_LABEL_SUCCESS:
            let u_labels = [...state.data];
            u_labels.forEach((label, i) => {
                if (label._id === action.payload._id) {
                    u_labels[i] = action.payload;
                    return false;
                }
            });
            return {
                ...state,
                isPutting: false,
                data: sortLexical(u_labels, "name", false, true),
            };
        case PUT_LABEL_FAILURE:
            return {
                ...state,
                isPutting: false,
                putError: action.payload,
            };

        case DELETE_LABEL_BEGIN:
            return {
                ...state,
                isDeleting: true,
                deleteError: null,
            };
        case DELETE_LABEL_SUCCESS:
            let d_labels = [...state.data].filter((label) => {
                return label._id !== action.payload;
            });
            return {
                ...state,
                isDeleting: false,
                data: sortLexical(d_labels, "name", false, true),
            };
        case DELETE_LABEL_FAILURE:
            return {
                ...state,
                isDeleting: false,
                deleteError: action.payload,
            };
        default:
            return state;
    }
}

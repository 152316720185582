import client from "./axios";

export const login = ({ username, password }) => {
    return client({
        method: "post",
        url: "/auth/login",
        data: { username, password },
    });
};

export const register = ({ username, email, password }) => {
    return client({
        method: "post",
        url: "/auth/register",
        data: { username, email, password },
    });
};

export const verify = () => {
    return client({
        method: "get",
        url: "/verify",
    });
};

import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Logout from "utils/logout";

import useMain from "./useMain";
import Loader from "components/Loader";
import Header from "components/Header";
import Manage from "views/Manage";
import Squads from "views/Squads";
import Settings from "views/Settings";
import Snippets from "views/Snippets";

const Main = () => {
    const { auth, fetchingSquads, squad } = useMain();

    if (auth !== true) {
        return (
            <div className="content-page">
                <Loader />
            </div>
        );
    }

    return (
        <Router>
            <Header />
            {fetchingSquads ? (
                <div className="content-page">
                    <Loader />
                </div>
            ) : (
                <Switch key={squad?._id || 0}>
                    <Route exact path="/manage" component={Manage} />
                    <Route exact path="/squads" component={Squads} />
                    <Route exact path="/settings" component={Settings} />
                    <Route path="/snippets" component={Snippets} />
                    <Route path="/logout" component={Logout} />
                    <Redirect from="/" to="/snippets" />
                </Switch>
            )}
        </Router>
    );
};

export default Main;

import client from "./axios";

export const getSnippet = ({ id }) => {
    return client({
        method: "get",
        url: `/snippets/${id}`,
    });
};

export const getSnippets = () => {
    return client({
        method: "get",
        url: "/snippets",
    });
};

export const getMoreSnippets = ({ category, subcategory, offset }) => {
    return client({
        method: "get",
        url: `/snippets`,
        params: { category, subcategory, offset },
    });
};

export const addSnippet = ({ item }) => {
    return client({
        method: "post",
        url: "/snippets",
        data: item,
    });
};

export const copySnippet = ({ id }) => {
    return client({
        method: "post",
        url: `/snippets/${id}`,
    });
};

export const updateSnippet = ({ id, item }) => {
    return client({
        method: "put",
        url: `/snippets/${id}`,
        data: item,
    });
};

export const removeSnippet = async ({ id }) => {
    return client({
        method: "delete",
        url: `/snippets/${id}`,
    });
};

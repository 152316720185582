import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";

import { toggleContextMenuOn } from "reduxStore/context-menu/context-menu.actions";
import { getModeIcon } from "utils/util";

const BUFFER = 4;

const Snippet = ({ snippet, hide, loadSnippet, activeSnippetId }) => {
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();
    useEffect(() => {
        document.querySelector(`#sni_${snippet._id}`).addEventListener("contextmenu", (e) => {
            e.preventDefault();

            let menuElement = document.querySelector("#snippet-context-menu");
            let menuHeight = menuElement.offsetHeight || 89;
            let menuWidth = menuElement.offsetWidth || 240;

            let clickPosition = getPosition(e);
            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;

            if (windowWidth - clickPosition.x < menuWidth + BUFFER) {
                clickPosition.x = windowWidth - menuWidth - BUFFER;
            }

            if (windowHeight - clickPosition.y < menuHeight + BUFFER) {
                clickPosition.y = windowHeight - menuHeight - BUFFER;
            }

            dispatch(
                toggleContextMenuOn({
                    key: "sidebarSnippet",
                    position: clickPosition,
                    data: {
                        snippet,
                    },
                })
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snippet]);

    function getPosition(e) {
        let posx = 0;
        let posy = 0;

        if (!e) e = window.event;

        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        } else if (e.clientX || e.clientY) {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        return {
            x: posx,
            y: posy,
        };
    }

    const [modeText, setModeText] = useState("");

    useEffect(() => {
        let modeText = "";
        if (snippet.mode === "javascript") {
            modeText = ".js";
        }
        if (snippet.mode === "json") {
            modeText = ".json";
        }
        if (snippet.mode === "text") {
            modeText = ".txt";
        }
        if (snippet.mode === "markdown") {
            modeText = ".md";
        }
        if (snippet.mode === "scss") {
            modeText = ".scss";
        }
        if (snippet.mode === "sql") {
            modeText = ".sql";
        }
        setModeText(modeText);
    }, [snippet.mode]);

    const handleDoubleClick = () => {
        loadSnippet({ id: snippet._id });
    };

    const handleKeyPress = (e) => {
        if (e.which === 13 || e.which === 32) {
            loadSnippet({ id: snippet._id });
            e.preventDefault();
        }
    };

    return (
        <div className="sidebar-item sidebar-snippet">
            <div
                className={classNames(
                    "sidebar-item-toggle noselect",
                    { "sidebar-toggle-closed": hide },
                    css({
                        "&:not(:focus)": {
                            border: activeSnippetId === snippet._id ? `1px solid ${theme.primary} !important` : null,
                        },
                    })
                )}
                tabIndex={0}
                id={`sni_${snippet._id}`}
                onDoubleClick={handleDoubleClick}
                onKeyPress={handleKeyPress}
            >
                <div className="toggle-title">
                    <i className={classNames(`${getModeIcon(snippet.mode)} fa-fw mr-2`, { "text-primary": activeSnippetId === snippet._id })} />
                    {snippet.name}
                    <span className="text-muted">{modeText}</span>
                </div>
            </div>
        </div>
    );
};

export default Snippet;
